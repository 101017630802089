import { useNavigate } from "react-router-dom";
import "./SmallSidebarCard.modules.css";

export default function SmallSidebarCard({ name, link, imagelink, loading }) {
    const navigate = useNavigate();
    if(loading) {
        return (
            <div className="card_smallsidebar_loading"></div>
        )
    }
    return (
        <button className="a card_smallsidebar" onClick={() => { navigate(link) }} style={{ cursor: "pointer" }}>
            <div className="card_smallsidebar_body">
            <span className="card_smallsidebar_body_avatar" data-loaded="">
                    <img src={imagelink} alt="" className="card_smallsidebar_body_avatar_img" />
                </span>
                <p className="card_smallsidebar_body_text">{name}</p>
            </div>
        </button>
    )
}