import { useNavigate } from 'react-router-dom';
import './NavigationItem.modules.css';

export default function NavigationItem({ name, path, icon, arrow }) {
    const navigate = useNavigate();
    function addArrow() {
        return (
            <span role="presentation" className="main_body_navigation_conatiner_navigation_nav_ol_navigationitem_arrow">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" focusable="false" className="main_body_navigation_conatiner_navigation_nav_ol_navigationitem_arrow_svg" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                </svg>
            </span>
        )
    }
    return (
        <li className="main_body_navigation_conatiner_navigation_nav_ol_navigationitem">
            <button className="a main_body_navigation_conatiner_navigation_nav_ol_navigationitem_a" onClick={() => { navigate(path) }} style={{ cursor: "pointer" }}>
                {icon}
                <p className="chakra-text css-0 main_body_navigation_conatiner_navigation_nav_ol_navigationitem_text">{name}</p>
            </button>
            {(arrow ? addArrow() : "")}
        </li>
    )
}