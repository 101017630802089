import { useState } from 'react';
import './ChannelListForm.modules.css';
import { useAlertDataContext } from '../../contextProvider/AlertContext';

export default function ChannelListSelectForm({ value, setValue, disabled, placeholder, notFoundPlaceholder, guildData }) {
    const [ show, setShow ] = useState(false);
    const { addAlert } = useAlertDataContext();
    //console.log(value)

    if(!value) value = ""

    const TextChannelIcon = (<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"></path></svg>)
    const createChannel = (name, id, type) => {
        return (
            <div tabIndex="-1" className="form_channellist_dropdownitems_channel" key={"" + id} onClick={() => { if(String(value).includes(id)) {addAlert({ title: "", style: "danger", size: "big", disabled: false, content1: "You cannot add the same channel (#" + name + ") to this channel list twice." })} else {setValue((value === "" || value === "0") ? id : value + "-" + id)}; console.log(value); setShow(false) }}>
                <div className="form_channellist_dropdownitems_channel_icon">
                    { type === 0 ? TextChannelIcon : null }
                </div>{name}
            </div>
        )
    }
    const createCategory = (name, id, channels) => {
        return (
            <>
                <div className="form_channellist_dropdownitems_category" key={id}>{name}</div>
                { channels.map(c => createChannel(c.name, c.id, c.type)) }
            </>
        )
    }
    return (
        <div className="form_channellist">
            <div className="form_channellist_dropdown " onClick={() => { if(!disabled) { setShow(!show) } }}>
                <div className="form_channellist_dropdown_channelcontainer">
                    {(String(value).split("-").map(id => {if(String(value).split("-").length === 1 && (String(id).length < 3)) return(placeholder); else { return(<div className="form_channellist_dropdown_channelcontainer_channel" data-id={id} onClick={() => {let x = value.replace(id, "").replace("--","").replace(/^[-]+|[-]+$/g, ''); setValue((x === "" ? "0" : x)); setShow(false)}}>{guildData.channels?.find(c => c.id === id) ? "# " + guildData.channels?.find(c => c.id === id)?.name : "#1" + notFoundPlaceholder}</div>)}}))}
                    {/* <div className="form_channellist_dropdown_channelcontainer_channel" data-id={value}>{ value/*{( String(value).length > 3 ? (guildData.channels?.find(c => c.id === value) ? guildData.channels?.find(c => c.id === value)?.name : placeholder) : (String(value).length === 0 || value === "0" ? placeholder : notFoundPlaceholder))}* /}</div>*/}
                </div>
                <div className="form_channellist_dropdown_icon">
                    <hr aria-orientation="vertical" className="form_channellist_dropdown_icon_separator" />
                    <div aria-hidden="true" className="form_channellist_dropdown_icon_container">
                        <svg viewBox="0 0 24 24" focusable="false" className="form_channellist_dropdown_icon_svg">
                            <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z">
                            </path>
                        </svg>
                    </div>
                </div>
            </div>
            { show ? (
                <div className="form_channellist_dropdownitems">
                    <div className="form_channellist_dropdownitems_container">
                        {guildData.channels?.sort((a, b) => b.position - a.position).filter(c => c.type === 0 && c.parent_id === null)?.map(d => createChannel(d.name, d.id, d.type))}
                        {guildData.channels?.filter(c => c.type === 0 && c.parent_id === null) ? (<hr className="form_channellist_dropdownitems_hr" />) : <></>}
                        {guildData.channels?.filter(c => c.type === 4).sort((a, b) => b.position - a.position).map(d => (<>
                            { createCategory(d.name, d.id, guildData.channels.filter(c => c.parent_id === d.id).sort((a, b) => b.position - a.position)) }
                            {( guildData.channels?.filter(c => c.type === 4).sort((a, b) => b.position - a.position).indexOf(d) < guildData.channels?.filter(c => c.type === 4).length - 1 ? <hr className="form_channellist_dropdownitems_hr" /> : <></> )}
                        </>))}
                    </div>
                </div>
            ) : null }
        </div>
    )
}