import { useNavigate } from "react-router-dom";
import "./SidebarCard.modules.css";

export default function SidebarCard({ name, link, imagelink, active }) {
    const navigate = useNavigate();
    return (
        <button className="a card_sidebar" onClick={() => { navigate(link) }}>
            <div className="card_sidebar_body">
            <span className="card_sidebar_body_avatar" data-loaded="">
                    <img src={(imagelink.includes("/null") ? "/images/Default_Icon_Grey.png" : imagelink)} alt="" className="card_sidebar_body_avatar_img" />
                </span>
                <p className="card_sidebar_body_text">{name}</p>
            </div>
        </button>
    )
}