const c = {
    white: "#ffffff",
    black: "#000000",
    textcolor: "#ffffff",
    color1: "#A0AEC0",
    color2: "#F56565",
    background1: "#08081c",
    background2: "#080e2c",
    background3: "#ffffff14",
    background4: "#7551FF",
    background5: "#422afb",
    background6: "#232c4f",
    borderColor1: "#ffffff3d",
    borderColor2: "#232c4f",
    shadow1: "#0204060f",
    gradientbackground1_from: "#7551ff",
    gradientbackground1_to: "#422afb",
    gradientbackground1_shadow: "#7551ff",
}

module.exports = {
    "--white": c.white,
    "--black": c.black,
    "--textcolor": c.textcolor,
    "--background1": c.background1,
    "--background2": c.background2,
    "--feature-enable-button-green": "#4dcf46",
    "--feature-enable-button-red": "#ee5d50",

    "--alert_primary-color": "#004085",
    "--alert_primary-background": "#cce5ff",
    "--alert_primary-borderColor": "#b8daff",
    "--alert_secondary-color": "#383d41",
    "--alert_secondary-background": "#e2e3e5",
    "--alert_secondary-borderColor": "#d6d8db",
    "--alert_success-color": "#155724",
    "--alert_success-background": "#d4edda",
    "--alert_success-borderColor": "#c3e6cb",
    "--alert_warning-color": "#856404",
    "--alert_warning-background": "#fff3cd",
    "--alert_warning-borderColor": "#ffeeba",
    "--alert_danger-color": "#721c24",
    "--alert_danger-background": "#f8d7da",
    "--alert_danger-borderColor": "#f5c6cb",
    "--alert_close-color": c.black,
    "--alert_close-shadow": c.white,

    "--badges_container-background": c.background2,
    
    "--banner-background-default": c.background4,
    "--banner_header-color-default": c.textcolor,
    "--banner_text-color-default": "#ffffffcc",
    "--banner_button_a-color-default": c.textcolor,
    "--banner_button_a-background-default": "#ffffff14",
    "--banner-background-team": "#dc2626",
    "--banner_header-color-team": c.textcolor,
    "--banner_text-color-team": "#ffffffcc",
    "--banner_button_a-color-team": c.textcolor,
    "--banner_button_a-background-team": "#ffffff14",

    "--card_main-color": c.textcolor,
    "--card_main-background": c.background2,
    "--card_main_header_avatar-color": c.textcolor,
    "--card_main_header_avatar-background": c.background5,
    "--card_main_header_avatar-borderColor": c.white,

    "--dashboard_container_title-color": c.textcolor,
    "--dashboard_container_text-color": c.color1,
    "--dashboard_container_btn_active_btn-background_from": c.gradientbackground1_from, // gradient from, kein gradient? beides gleich
    "--dashboard_container_btn_active_btn-background_to": c.gradientbackground1_to, // gradient to, kein gradient? beides gleich
    "--dashboard_container_btn_active_btn-shadow": c.gradientbackground1_shadow,
    "--dashboard_container_btn_active_btn-color": c.textcolor,
    "--dashboard_container_btn-background": c.background3,
    "--dashboard_container_btn-color": c.textcolor,

    "--error_svg-color": c.color2,
    "--error_text-color": c.color2,
    "--error_button-color": c.textcolor,
    "--error_button-background": "#EE5D50",

    "--featureBody_container_form_card_description-color": c.color1,
    "--featureBody_container_form_card_btn_button-color": c.textcolor,
    "--featureBody_save-background": c.background2,
    "--featureBody_save-shadow": c.shadow1,
    "--featureBody_save_buttons_save-color": c.textcolor,
    "--featureBody_save_buttons_save-background_from": c.gradientbackground1_from,
    "--featureBody_save_buttons_save-background_to": c.gradientbackground1_to,
    "--featureBody_save_buttons_save-shadow": c.gradientbackground1_shadow,
    "--featureBody_save_buttons_discard-background": c.background3,

    "--featureCard-color": c.textcolor,
    "--featureCard-background": c.background2,
    "--featureCard_body_activeImg-color": c.textcolor,
    "--featureCard_body_activeImg-background": c.background4,
    "--featureCard_body_inactiveImg-color": c.background5,
    "--featureCard_body_inactiveImg-background": "#7451ff2d",
    "--featureCard_body_text_description-color": c.color1,
    "--featureCard_body_btn_activeButton-color": c.textcolor,
    "--featureCard_body_btn_activeButton-background_from": c.gradientbackground1_from,
    "--featureCard_body_btn_activeButton-background_to": c.gradientbackground1_to,
    "--featureCard_body_btn_activeButton-shadow": c.gradientbackground1_shadow,
    "--featureCard_body_btn_inactiveButton-color": c.textcolor,
    "--featureCard_body_btn_inactiveButton-background": c.background3,
    "--featureCard_body_btn_inactiveButton-background-hover": "#ffffff29",

    "--featuresidebar-background": c.background2,
    "--featuresidebar_body_backarrow_button-background": c.background3,
    "--featuresidebar_body_backarrow_svg-color": c.textcolor,
    "--featuresidebar_body_features_settings-color": c.color1,
    "--featuresidebar_body_features_settings_icon-color": c.textcolor,
    "--featuresidebar_body_features_settings_icon-borderColor": c.borderColor1,
    "--featuresidebar_body_features_settings_icon-shadow": "#7090b04d",
    "--featuresidebar_body_separator_space-background": "#4A5568",
    "--featuresidebar_body_separator_text-color": "#a3aed0",

    "--featuresidebar_body_features_feature-color": c.color1,
    "--featuresidebar_body_features_feature_active-color": c.textcolor,
    "--featuresidebar_body_features_feature_active-background": "#ffffff0f",
    "--featuresidebar_body_features_feature_icon-borderColor": "#00000014",
    "--featuresidebar_body_features_feature_icon-shadow": "#7090b04d",
    "--featuresidebar_body_features_feature_icon_active-color": c.textcolor,
    "--featuresidebar_body_features_feature_icon_active-background": c.background4,
    "--featuresidebar_body_features_feature_icon_active-borderColor": c.borderColor1,
    "--featuresidebar_body_features_feature_icon_active-shadow": "#0000005c",

    "--guildbody_features_header-color": c.textcolor,

    "--loading_text-color": c.textcolor,

    "--main_body_navigation_container-background": "#08081c80",
    "--main_body_navigation_conatiner_navigation_title-color": c.textcolor,
    "--main_body_navigation_container_profile-background": c.background2,
    "--main_body_navigation_container_profile-shadow": c.shadow1,
    "--main_body_navigation_container_profile_sidebartoggle_container_svg-color": c.textcolor,
    "--main_body_navigation_container_profile_darkmodetoggle_svg-color": c.textcolor,
    "--main_body_navigation_container_profile_dropdowntoggle_container_imgcontainer-color": c.textcolor,
    "--main_body_navigation_container_profile_dropdowntoggle_container_imgcontainer-background": "#11047a",
    "--main_body_navigation_container_profile_dropdowntoggle_container_imgcontainer-borderColor": c.white,
    "--main_body_navigation_container_profile_dropdown_container-background": c.background2,
    "--main_body_navigation_container_profile_dropdown_container-shadow": c.shadow1,
    "--main_body_navigation_container_profile_dropdown_container_header_title-color": c.textcolor,
    "--main_body_navigation_container_profile_dropdown_container_header_title-borderColor": "#878cbd4d",
    "--main_body_navigation_container_profile_dropdown_container_items_button-color": c.color2,

    "--main_body_navigation_conatiner_navigation_nav_ol_navigationitem_a-color": "#e9e3ff",
    "--main_body_navigation_conatiner_navigation_nav_ol_navigationitem_a-background": "#7551ff33",
    "--main_body_navigation_conatiner_navigation_nav_ol_navigationitem_arrow_svg-color": "#e9e3ff",

    "--sidebar-background": c.background2,
    "--sidebar_header-background": c.background4,
    "--sidebar_header_title-color": c.textcolor,
    "--sidebar_body_searchbar_body_icon_button-color": "#ffffffeb",
    "--sidebar_body_searchbar_body_icon_svg-color": c.textcolor,
    "--sidebar_body_searchbar_body_input-color": c.textcolor,
    "--sidebar_body_searchbar_body_input-background": c.background1,

    "--card_sidebar-color": c.textcolor,
    "--card_sidebar-background": c.background1,
    "--card_sidebar-shadow1": "#0000001a",
    "--card_sidebar-shadow2": "#0000000f",
    "--card_sidebar_body_avatar-color": c.textcolor,
    "--card_sidebar_body_avatar-background": c.background5,
    "--card_sidebar_body_avatar-borderColor": c.white,
    
    "--card_sidebarloading-background": c.background6,
    "--card_sidebarloading-borderColor": c.background2,
    "--card_sidebarloading-background-animation-from": c.background6,
    "--card_sidebarloading-background-animation-to": c.background2,
    "--card_sidebarloading-borderColor-animation-from": c.background6,
    "--card_sidebarloading-borderColor-animation-to": c.background2,

    "--sidebarnavigationitem_main-color": c.color1,
    "--sidebarnavigationitem_main_enabled-color": c.textcolor,
    "--sidebarnavigationitem_main_enabled-background": "#ffffff0f",
    "--sidebarnavigationitem_main_icon_enabled-color": c.textcolor,
    "--sidebarnavigationitem_main_icon_enabled-background": c.background4,
    "--sidebarnavigationitem_main_icon_enabled-borderColor": c.borderColor1,
    "--sidebarnavigationitem_main_icon_enabled-shadow": "#7451ff9c",
    "--sidebarnavigationitem_main_icon-color": c.textcolor,
    "--sidebarnavigationitem_main_icon-borderColor": c.borderColor1,
    "--sidebarnavigationitem_main_icon-shadow": "#7090b04d",

    "--sidebarprofile-color": c.textcolor,
    "--sidebarprofile-background": c.background2,
    "--sidebarprofile-shadow1": "#0000001a",
    "--sidebarprofile-shadow2": "#0000000f",
    "--sidebarprofile_body_icon-color": c.white,
    "--sidebarprofile_body_icon-background": c.background5,
    "--sidebarprofile_body_icon-borderColor": c.white,
    "--sidebarprofile_body_settingsicon-color": c.white,
    "--sidebarprofile_body_settingsicon-background": c.background3,

    "--smallsidebaroverlay_opacity-background": "#0000007a",
    "--smallsidebaroverlay_body_conatiner-background": c.background2,
    "--smallsidebaroverlay_body_conatiner-shadow1": "#0000001a",
    "--smallsidebaroverlay_body_conatiner-shadow2": "#00000033",
    "--smallsidebaroverlay_body_conatiner-shadow3": "#00000066",
    "--smallsidebar_header-background": c.background4,
    "--smallsidebar_header_title-color": c.textcolor,
    "--smallsidebar_body_searchbar_body_icon_button-color": "#ffffffeb",
    "--smallsidebar_body_searchbar_body_icon_svg-color": c.textcolor,
    "--smallsidebar_body_searchbar_body_input-color": c.textcolor,
    "--smallsidebar_body_searchbar_body_input-background": c.background1,

    "--card_smallsidebar-color": c.textcolor,
    "--card_smallsidebar-background": c.background1,
    "--card_smallsidebar-shadow1": "#0000001a",
    "--card_smallsidebar-shadow2": "#0000000f",
    "--card_smallsidebar_loading-background": c.background6,
    "--card_smallsidebar_loading-borderColor": "#080e2c",
    "--card_smallsidebar_body_avatar-color": c.textcolor,
    "--card_smallsidebar_body_avatar-background": c.background5,
    "--card_smallsidebar_body_avatar-borderColor": "#1A202C",

    "--smallsidebarnavigationitem_main_enabled-color": c.textcolor,
    "--smallsidebarnavigationitem_main_enabled-background": "#ffffff0f",
    "--smallsidebarnavigationitem_main-color": c.color1,
    "--smallsidebarnavigationitem_main_icon_enabled-color": c.textcolor,
    "--smallsidebarnavigationitem_main_icon_enabled-background": c.background4,
    "--smallsidebarnavigationitem_main_icon_enabled-borderColor": c.borderColor1,
    "--smallsidebarnavigationitem_main_icon_enabled-shadow": "#7451ff9c",
    "--smallsidebarnavigationitem_main_icon-color": c.textcolor,
    "--smallsidebarnavigationitem_main_icon-borderColor": c.borderColor1,
    "--smallsidebarnavigationitem_main_icon-shadow": "#7090b04d",
    
    "--smallsidebarprofile-color": c.textcolor,
    "--smallsidebarprofile-background": c.background2,
    "--smallsidebarprofile-shadow1": "#0000001a",
    "--smallsidebarprofile-shadow2": "#0000000f",
    "--smallsidebarprofile_body_icon-color": c.textcolor,
    "--smallsidebarprofile_body_icon-background": c.background5,
    "--smallsidebarprofile_body_icon-borderColor": "#1A202C",
    "--smallsidebarprofile_body_settingsicon-color": c.white,
    "--smallsidebarprofile_body_settingsicon-background": c.background3,

    "--userbody_profile_banner-background": c.background4,
    "--userbody_profile_main_image-color": c.textcolor,
    "--userbody_profile_main_image-background": c.background5,
    "--userbody_profile_main_image-borderColor": c.white,
    "--userbody_profile_main_image-shadow1": c.white,
    "--userbody_profile_main_image-shadow2": c.background2,
    "--userbody_profile_main_image-shadow3": "#00000000",
    "--userbody_profile_main_username-color": c.textcolor,
    "--userbody_settings-color": c.textcolor,
    "--userbody_settings-background": c.background2,
    "--userbody_settings_container_button-color": c.textcolor,
    "--userbody_settings_container_button-background": "#EE5D50",

    "--form-background": c.background2,
    "--form-shadow": c.shadow1,
    "--form_title-color": c.textcolor,
    "--form_description-color": c.color1,
    
    "--form_channelselect_dropdown-background": "#00000029",
    "--form_channelselect_dropdown-borderColor": "#232c4f",
    "--form_channelselect_dropdown_channelcontainer_text-color": "#A3AED0",
    "--form_channelselect_dropdown_icon_container-borderColor": "#ffffff0a",
    "--form_channelselect_dropdown_icon_svg-color": c.white,
    "--form_channelselect_dropdownitems-color": c.white,
    "--form_channelselect_dropdownitems-borderColor": c.borderColor2,
    "--form_channelselect_dropdownitems_container-background": c.background1,
    "--form_channelselect_dropdownitems_container-borderColor": c.borderColor2,
    "--form_channelselect_dropdownitems_container-shadow": c.shadow1,
    "--form_channelselect_dropdownitems_category-background": c.background2,
    "--form_channelselect_dropdownitems_hr-background": c.background6,
    
    "--form_channellist_dropdown-background": "#00000029",
    "--form_channellist_dropdown-borderColor": "#232c4f",
    "--form_channellist_dropdown_channelcontainer_channel-color": "#A3AED0",
    "--form_channellist_dropdown_icon_container-borderColor": "#ffffff0a",
    "--form_channellist_dropdown_icon_svg-color": c.white,
    "--form_channellist_dropdownitems-color": c.white,
    "--form_channellist_dropdownitems-borderColor": c.borderColor2,
    "--form_channellist_dropdownitems_container-background": c.background1,
    "--form_channellist_dropdownitems_container-borderColor": c.borderColor2,
    "--form_channellist_dropdownitems_container-shadow": c.shadow1,
    "--form_channellist_dropdownitems_category-background": c.background2,
    "--form_channellist_dropdownitems_hr-background": c.background6,

    "--form_date_tileContent-color": "#422afb",

    "--form_dropdown_menu-background": "#00000029",
    "--form_dropdown_menu-borderColor": "#232c4f",
    "--form_dropdown_menu_itemcontainer_text-color": "#A3AED0",
    "--form_dropdown_menu_icon_container-borderColor": "#ffffff0a",
    "--form_dropdown_menu_icon_svg-color": c.white,
    "--form_dropdown_items-color": c.white,
    "--form_dropdown_items-borderColor": c.borderColor2,
    "--form_dropdown_items_container-background": c.background1,
    "--form_dropdown_items_container-borderColor": c.borderColor2,
    "--form_dropdown_items_container-shadow": c.shadow1, 

    "--form_switch_input-color": "#2D3748",
    "--form_switch_holder-background": "#1d2343",
    "--form_switch_holder_active-background": "#0e2786c9",
    "--form_switch_switch-background": c.white,
    "--form_switch_switch_active-background": c.white,
}