import "./FeatureSidebarCard.modules.css";
import { useLanguage } from "../contextProvider/LanguageContext";
import { useNavigate } from "react-router-dom";

export default function FeatureSidebarCard({ config, id, feature, active }) {
    const { l } = useLanguage();
    const navigate = useNavigate();
    return (
        <button className={"a featuresidebar_body_features_feature" + (active ? "_active" : "")} onClick={() => { navigate("/guilds/" + id + "/features/" + feature) }} style={{ cursor: "pointer" }}>
            <div className={"featuresidebar_body_features_feature_icon" + (active ? "_active" : "")}>
                { config.features.find(f => f.name === feature).icon }
            </div>
            <p className="featuresidebar_body_features_feature_text">{l("features." + feature + "-title")}</p>
        </button>
    )
}