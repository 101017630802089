import SidebarProfile from "./SidebarProfile";
import "./FeatureSidebar.modules.css";
import { useLanguage } from "../contextProvider/LanguageContext";
import { useLocation, useNavigate } from "react-router-dom";
import FeatureSidebarCard from "./FeatureSidebarCard";
import CustomFeatureSidebarCard from "./CustomFeatureSidebarCard";
import { useUserDataContext } from "../contextProvider/UserContext";

export default function FeatureSidebar({ config, id }) {
    const { pathname } = useLocation();
    const { l } = useLanguage();
    const navigate = useNavigate();
    const { getUser } = useUserDataContext();
    const user = getUser();
    return (
        <div className="featuresidebar" key={"featuressidebar." + id} >
            <div style={{ opacity: 1, transform: "none" }}>
                <div className="featuresidebar_body">
                    <button className="a featuresidebar_body_backarrow" onClick={() => { navigate("/guilds/" + id) }} style={{ cursor: "pointer" }}>
                        <div type="button" className="featuresidebar_body_backarrow_button" aria-label="back">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" focusable="false" className="featuresidebar_body_backarrow_svg" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z">
                                </path>
                            </svg>
                        </div>
                        <p className="featuresidebar_body_settings">
                        </p>
                    </button>
                    <div className="featuresidebar_body_features">
                        <CustomFeatureSidebarCard config={config} id={id} active={(pathname.includes("/settings") ? true : false)} name={l("general.Settings")} link={"/guilds/" + id + "/settings"} icon={config.getIcon("Settings")} />
                        <CustomFeatureSidebarCard config={config} id={id} active={(pathname.includes("/vanity") ? true : false)} name={l("general.Vanity")} link={"/guilds/" + id + "/vanity"} icon={config.getIcon("Settings")} />
                        <div className="featuresidebar_body_separator">
                            <div className="featuresidebar_body_separator_space"></div>
                            <p className="featuresidebar_body_separator_text">{l("general.Features")}</p>
                            <div className="featuresidebar_body_separator_space"></div>
                        </div>
                        {config.features.map(f => (f.name.startsWith("settings") || f.name === "vanity" ? <></> : <FeatureSidebarCard config={config} id={id} feature={f.name} active={(pathname.includes("/" + f.name) ? true : false)} key={"featuresidebarcard." + f.name} />))}
                    </div>
                </div>
            </div>
            <div className="featuresidebar_space">
            </div>
            <SidebarProfile config={config} user={user} />
        </div>
    )
}