const setCookie = (name, value, expiredays, secure = "Strict") => {
    const d = new Date();
    d.setTime(d.getTime() + (expiredays * 24 * 60 * 60 * 1000));
    let expires = ";expires=" + d.toUTCString();
    if (expiredays === 0) expires = "";
    document.cookie = name + "=" + value + expires + ";path=/;secure;samesite=" + secure;
}
const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
const checkCookie = (name) => {
    let user = getCookie(name);
    if (user !== "") {
        return true;
    } else {
        return false;
    }
}
const deleteCookie = (name) => {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

module.exports.setCookie = setCookie;
module.exports.getCookie = getCookie;
module.exports.checkCookie = checkCookie;
module.exports.deleteCookie = deleteCookie;