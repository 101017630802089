import SmallSidebarCard from "./SmallSidebarCard";
import SmallSidebarNavigationItem from "./SmallSidebarNavigationItem";
import SmallSidebarProfile from "./SmallSidebarProfile";
import "./SmallSidebar.modules.css";
import { useLanguage } from "../contextProvider/LanguageContext";
import { useLocation } from "react-router-dom";
import { useGuildDataContext } from "../contextProvider/GuildContext";
import { useUserDataContext } from "../contextProvider/UserContext";
import { useState } from "react";

export default function SmallSidebar({ config, show, exit }) {
    const [ value, setValue ] = useState("");
    const { pathname } = useLocation();
    const { getAllGuilds } = useGuildDataContext();
    const { l } = useLanguage();
    const { getUser } = useUserDataContext();
    const user = getUser();
    let display = { display: "none" }
    if(show) { display = {}}
    return (
        <div className="smallsidebaroverlay" style={display}>
            <div className="smallsidebaroverlay_opacity" style={{opacity: "1"}} data-aria-hidden="true" aria-hidden="true">
            </div>
            <div className="smallsidebaroverlay_body">
                <div tabIndex="-1" aria-modal="true" className="smallsidebaroverlay_body_conatiner" style={{position: "fixed", right: "0px", top: "0px", bottom: "0px", width: "100%", transform: "translateX(0%) translateY(0px) translateZ(0px)"}} >
                    <button type="button" aria-label="Close" className="smallsidebaroverlay_body_conatiner_button" onClick={exit}>
                        <svg viewBox="0 0 24 24" focusable="false" className="smallsidebaroverlay_body_conatiner_button_svg" aria-hidden="true">
                            <path fill="currentColor" d="M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z">
                            </path>
                        </svg>
                    </button>
                    <div className="smallsidebar">
                        <div className="smallsidebar_container">
                            <div className="smallsidebar_header">
                                <h2 className="smallsidebar_header_title">{config.name}</h2>
                            </div>
                            <div className="smallsidebar_body">
                                <div className="smallsidebar_body_navigationitems">
                                    {config.navigationItems.map(i => <SmallSidebarNavigationItem name={i.name} link={i.link} svgicon={i.svgicon} enabled={i.enabledPaths.includes(pathname)} key={"smallsidebarnavigationitem." + i.link} />)}
                                </div>
                                <div className="smallsidebar_body_searchbar">
                                    <div className="smallsidebar_body_searchbar_body">
                                        <div className="smallsidebar_body_searchbar_body_icon">
                                            <button type="button" className="smallsidebar_body_searchbar_body_icon_button" aria-label="search">
                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" focusable="false" className="chakra-icon css-1geehbd smallsidebar_body_searchbar_body_icon_svg" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z">
                                                    </path>
                                                </svg>
                                            </button>
                                        </div>
                                        <input placeholder={l("general.Searching")} className="smallsidebar_body_searchbar_body_input" value={value} onChange={e => setValue(e.target.value)} />
                                    </div>
                                </div>
                                <div className="smallsidebar_body_cards">
                                    {getAllGuilds().map(g => <SmallSidebarCard name={g.name} link={"/guilds/" + g.id} imagelink={"https://cdn.discordapp.com/icons/" + g.id + "/" + g.icon + ".png"} key={"smallsidebarcard." + g.id} />)}
                                </div>
                            </div>
                            <div className="smallsidebarspace"></div>
                            <SmallSidebarProfile config={config} user={user} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}