import { useEffect, useMemo, useRef, useState } from 'react';
import Navigation from '../components/Navigation';
import Sidebar from '../components/Sidebar';
import SmallSidebar from '../components/SmallSidebar';
import UserBody from '../components/UserBody';
import { checkCookie } from '../tools/CookieFunctions';
import { useLoginContext } from '../contextProvider/LoginContext';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import { useUserDataContext } from '../contextProvider/UserContext';
import ErrorSystem from '../components/ErrorSystem';

export default function UserProfile({ config }) {
    const [showSmallSidebar, setShowSmallSidebar] = useState(false);
    let { userId } = useParams();
    const { getUser } = useUserDataContext(userId);
    const user = getUser();
    const { isLoggedIn } = useLoginContext();
    const [, setRender] = useState(false);
    const cookies = useRef({
        session: checkCookie('session'),
        token: checkCookie('token'),
        hasToken: checkCookie('hasToken')
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newCookies = {
                session: checkCookie('session'),
                token: checkCookie('token'),
                hasToken: checkCookie('hasToken')
            };

            if (
                newCookies.session !== cookies.current.session ||
                newCookies.token !== cookies.current.token ||
                newCookies.hasToken !== cookies.current.hasToken
            ) {
                cookies.current = newCookies;
                setRender(prev => !prev);
            }
        }, 500);

        return () => clearInterval(intervalId);
    }, []);

    const memoizedComponent = useMemo(() => {
        function enableSmallSidebar() { setShowSmallSidebar(true) };
        function disableSmallSidebar() { setShowSmallSidebar(false) };
        return (
            <div className="main">
                <Sidebar config={config} loading={(cookies.current.session && cookies.current.token && cookies.current.hasToken && isLoggedIn())} />
                <div className="main_body">
                    <Navigation config={config} showSmallSidebar={enableSmallSidebar} />
                    <div className="main_body_container">
                        <ErrorSystem guildData={{}} guildId={{}} page={"UserProfile"} cookies={cookies} config={config} isLoggedIn={isLoggedIn} children={(<UserBody config={config} user={user} />)} />
                    </div>
                    <Footer />
                </div>
                <SmallSidebar config={config} show={showSmallSidebar} exit={disableSmallSidebar} />
            </div>
        );
    }, [config, user, isLoggedIn, cookies, showSmallSidebar]);

    return memoizedComponent;
}