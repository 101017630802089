import { useNavigate, useParams } from 'react-router-dom';
import './Banner.modules.css';
import { useWebSocketContext } from '../contextProvider/WebsocketContext';

export default function Banner({ title, content, colorScheme, buttons }) {
    const navigate = useNavigate();
    const { sendMessage } = useWebSocketContext();
    const { guildId, userId, featureId } = useParams();

    if(!colorScheme) colorScheme = "default"
    return (
        <div class={"banner banner_colors_" + colorScheme}>
            <h2 class={"banner_header banner_header_colors_" + colorScheme}>{title}</h2>
            <p class={"banner_text_colors_" + colorScheme}>{content}</p>
            <div role="group" class="banner_button" data-orientation="horizontal">
                {buttons.map(b => (<button class={"a banner_button_a banner_button_a_colors_" + colorScheme} onClick={() => {
                    if(b.link !== "") {
                        if(b.link.startsWith("http")) window.location.href = b.link; 
                        else navigate(b.link) 
                    } else { 
                        sendMessage({ type: "GuildAction", subtype: b.action, data: { guildId: guildId, userId: userId, featureId: featureId} })
                    }
                }} style={{cursor: "pointer"}}><span class="banner_button_icon">{b.icon}</span>{b.title}</button>
                ))}
            </div>
        </div>
    )
}