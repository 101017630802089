import './Alert.modules.css';

export default function Alert({ alert, setDisabled }) {

    if (!alert.disabled) {
        return (<>
            {alert.size === "small" ? (
                <div className={"alert alert_" + alert.style + " alert_dismissible fade show"} role="alert">
                    <strong>{alert.title}</strong> {alert.content1}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setDisabled(alert)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            ) : (
                <div className={"alert alert_" + alert.style + " alert_dismissible fade show"} role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setDisabled(alert)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="alert_heading">{alert.title}</h4>
                    {alert.content2 && alert.content2 !== "" ? (<>
                        <p className="alert_content">{alert.content1}</p>
                        <hr />
                        <p className="alert_content-end">{alert.content2}</p>
                    </>) : (
                        <p className="alert_content-end">{alert.content1}</p>
                    )}
                </div>
            )
            }
        </>)
    } else {
        return (<></>)
    }
}