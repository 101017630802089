import { Navigate, useParams } from "react-router-dom";
import Loading from "./Loading";

export default function Navigator({ to, replace }) {
    const { guildId } = useParams();

    if(to.startsWith("http")) {
        window.location.href = to;
        return (<Loading/>)
    } else {
        return (
            <Navigate to={to.replace("{guildId}", guildId)} replace={replace} />
        )
    }
}