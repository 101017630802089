import { setCookie, getCookie, checkCookie } from '../../../tools/CookieFunctions';
import Navigator from '../../../components/Navigator';

export default function Auth() {
  if (!checkCookie("loginState")) {
    function generateRandomString(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    }
    setCookie("loginState", generateRandomString(12), 0.00694, "Strict")
  } else {
    setCookie("loginState", getCookie("loginState"), 0.00694, "Strict")
  }

  if (true) return (<Navigator to={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=${process.env.REACT_APP_SCOPE}&state=${getCookie("loginState")}`}></Navigator>);
  //return (<Navigator to={`/`}></Navigator>)
}