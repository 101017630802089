import './Form.modules.css';
import TextForm from './TextForm';
//import DateForm from './DateForm';
import ColorForm from './ColorForm';
//import FileForm from './FileForm';
import ChannelSelectForm from './ChannelSelectForm';
import ChannelListSelectForm from './ChannelListForm';
import SwitchForm from './SwitchForm';
import DropdownForm from './DropdownForm';

export default function Form({ type, value, setValue, title, description, disabled, placeholder, notFoundPlaceholder, guildData, options }) {
    return (
        <div className="form">
            {type === "switch" ? <>
                <div className="form_small">
                    <div>
                        <label className="form_title">{title}</label>
                        <p className="form_description">{description}</p>
                    </div>
                    <SwitchForm value={value} setValue={setValue} disabled={disabled} placeholder={placeholder} notFoundPlaceholder={notFoundPlaceholder} guildData={guildData} />
                </div>
            </> : <>
                <label className="form_title">{title}</label>
                <p className="form_description">{description}</p>
                <div className="form_holder">
                </div>
                {type === "text" ? <TextForm value={value} setValue={setValue} disabled={disabled} placeholder={placeholder} notFoundPlaceholder={notFoundPlaceholder} guildData={guildData} options={options} /> : null}
                {type === "date" ? /*<DateForm value={value} setValue={setValue} disabled={disabled} placeholder={placeholder} notFoundPlaceholder={notFoundPlaceholder} guildData={guildData} options={options} />*/null : null}
                {type === "color" ? <ColorForm value={value} setValue={setValue} disabled={disabled} placeholder={placeholder} notFoundPlaceholder={notFoundPlaceholder} guildData={guildData} options={options} /> : null}
                {type === "file" ? /*<FileForm value={value} setValue={setValue} disabled={disabled} placeholder={placeholder} notFoundPlaceholder={notFoundPlaceholder} guildData={guildData} options={options} />*/null : null}
                {type === "channel" ? <ChannelSelectForm value={value} setValue={setValue} disabled={disabled} placeholder={placeholder} notFoundPlaceholder={notFoundPlaceholder} guildData={guildData} options={options} /> : null}
                {type === "channelList" ? <ChannelListSelectForm value={value} setValue={setValue} disabled={disabled} placeholder={placeholder} notFoundPlaceholder={notFoundPlaceholder} guildData={guildData} options={options} /> : null}
                {type === "dropdown" ? <DropdownForm value={value} setValue={setValue} disabled={disabled} placeholder={placeholder} notFoundPlaceholder={notFoundPlaceholder} guildData={guildData} options={options} /> : null}
            </>}
        </div>
    )
}








/*
<div role="group" className="chakra-form-control css-1dnmxyc">
    <label id="field-:rn:-label" for="field-:rn:" className="chakra-form__label css-as0d0e">Date</label>
    <p className="chakra-text css-amjzt8">The date of today</p>
    <div className="css-148yfu2">
    </div>
    <Calendar onClickDay={setDate} value={date} view={'month'} tileContent={<div style={{ color: "#422AFB" }} />} prevLabel={<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="currentColor" d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6l6 6z" /></svg>} nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="currentColor" d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6z" /></svg>} />
    {/*<div className="react-calendar">
        <div className="react-calendar__navigation">
            <button aria-label="" className="react-calendar__navigation__arrow react-calendar__navigation__prev2-button" type="button">«</button>
            <button aria-label="" className="react-calendar__navigation__arrow react-calendar__navigation__prev-button" type="button">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" focusable="false" className="chakra-icon css-xgtpqo" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z">
                    </path>
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z">
                    </path>
                </svg>
            </button>
            <button aria-label="" className="react-calendar__navigation__label" style={{ flexGrow: 1 }} type="button">
                <span className="react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from">April 2024</span>
            </button>
            <button aria-label="" className="react-calendar__navigation__arrow react-calendar__navigation__next-button" type="button">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" focusable="false" className="chakra-icon css-xgtpqo" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z">
                    </path>
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z">
                    </path>
                </svg>
            </button>
            <button aria-label="" className="react-calendar__navigation__arrow react-calendar__navigation__next2-button" type="button">»</button>
        </div>
        <div className="react-calendar__viewContainer">
            <div className="react-calendar__month-view ">
                <div style={{ "display": "flex", "alignItems": "flex-end" }}>
                    <div style={{ "flexGrow": "1", "width": "100%" }}>
                        <div className="react-calendar__month-view__weekdays" style={{ "display": "flex" }}>
                            <div className="react-calendar__month-view__weekdays__weekday" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }}>
                                <abbr aria-label="Montag" title="Montag">Mo</abbr>
                            </div>
                            <div className="react-calendar__month-view__weekdays__weekday" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }}>
                                <abbr aria-label="Dienstag" title="Dienstag">Di</abbr>
                            </div>
                            <div className="react-calendar__month-view__weekdays__weekday" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }}>
                                <abbr aria-label="Mittwoch" title="Mittwoch">Mi</abbr>
                            </div>
                            <div className="react-calendar__month-view__weekdays__weekday" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }}>
                                <abbr aria-label="Donnerstag" title="Donnerstag">Do</abbr>
                            </div>
                            <div className="react-calendar__month-view__weekdays__weekday" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }}>
                                <abbr aria-label="Freitag" title="Freitag">Fr</abbr>
                            </div>
                            <div className="react-calendar__month-view__weekdays__weekday react-calendar__month-view__weekdays__weekday--weekend" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }}>
                                <abbr aria-label="Samstag" title="Samstag">Sa</abbr>
                            </div>
                            <div className="react-calendar__month-view__weekdays__weekday react-calendar__month-view__weekdays__weekday--weekend" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }}>
                                <abbr aria-label="Sonntag" title="Sonntag">So</abbr>
                            </div>
                        </div>
                        <div className="react-calendar__month-view__days" style={{ "display": "flex", "flexWrap": "wrap" }}>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="1. April 2024">1</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="2. April 2024">2</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="3. April 2024">3</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="4. April 2024">4</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="5. April 2024">5</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="6. April 2024">6</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="7. April 2024">7</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="8. April 2024">8</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="9. April 2024">9</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="10. April 2024">10</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="11. April 2024">11</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="12. April 2024">12</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="13. April 2024">13</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="14. April 2024">14</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="15. April 2024">15</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="16. April 2024">16</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="17. April 2024">17</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="18. April 2024">18</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="19. April 2024">19</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="20. April 2024">20</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="21. April 2024">21</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__tile--now react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="22. April 2024">22</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="23. April 2024">23</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="24. April 2024">24</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="25. April 2024">25</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="26. April 2024">26</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="27. April 2024">27</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="28. April 2024">28</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="29. April 2024">29</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="30. April 2024">30</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--neighboringMonth" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="1. Mai 2024">1</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--neighboringMonth" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="2. Mai 2024">2</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--neighboringMonth" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="3. Mai 2024">3</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend react-calendar__month-view__days__day--neighboringMonth" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="4. Mai 2024">4</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                            <button className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend react-calendar__month-view__days__day--neighboringMonth" style={{ "flex": "0 0 14.2857%", "overflow": "hidden" }} type="button">
                                <abbr aria-label="5. Mai 2024">5</abbr>
                                <p className="chakra-text css-vykmad">
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>* /}
</div>
*/