import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useWebSocketContext } from './WebsocketContext';
import { useAlertDataContext } from './AlertContext';

const GuildContext = createContext({});

export default GuildContext;

export const GuildDataProvider = ({ children }) => {
  const { message, sendMessage } = useWebSocketContext();
  const [guildData, setGuildData] = useState({});
  const askTime = useRef({});
  const { addAlert } = useAlertDataContext();

  function tryAsk(guildId) {
    let thisAskTime = askTime.current[guildId] || new Date(1970, 0, 1, 0, 0, 0, 0);
    if (Math.floor((new Date()).getTime() - new Date(thisAskTime).getTime()) >= 20000) {
      sendMessage({ type: "Guild", data: { id: guildId } })
      askTime.current[guildId] = new Date()
    }
  }

  window.forceGuildReload = (id = "*") => {
    sendMessage({ type: "Guild", data: { id } });
    if (id !== "*") {
      askTime.current[id] = new Date()
    }
  }

  const updateGuildData = (guildId, oldData, newData) => {    
    function findDifferences(obj1, obj2, path = '') {
      let differences = [];
    
      for (let key in obj1) {
        if (obj1.hasOwnProperty(key)) {
          let fullPath = path ? `${path}.${key}` : String(key);
          
          if (typeof obj1[key] === 'object' && obj1[key] !== null && !Array.isArray(obj1[key])) {
            differences = differences.concat(findDifferences(obj1[key], obj2[key], fullPath));
          } else if (obj1[key] !== obj2[key]) {
            differences.push({
              variable: fullPath,
              oldvalue: obj1[key],
              newvalue: obj2[key]
            });
          }
        }
      }
    
      for (let key in obj2) {
        if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
          let fullPath = path ? `${path}.${key}` : String(key);
          differences.push({
            variable: fullPath,
            oldvalue: undefined,
            newvalue: obj2[key]
          });
        }
      }
    
      return differences;
    }

    console.log(findDifferences(oldData.current || oldData, newData))
    
    sendMessage({ type: "GuildAction", subtype: "update", data: { id: guildId, update: findDifferences(oldData.current || oldData, newData) } });
  };

  useEffect(() => {
    if (message && !message.processedByGuildContext) {
      message.processedByGuildContext = true;
      if (message && message.type === 'AuthAccepted') {
        setGuildData(prevGuildData => {
          let gData = {};
          message.data.guilds.forEach(g => {
            gData[g.id] = {
              ...prevGuildData[g.id],
              ...g,
            };
          });
          return gData;
        });
      } else if (message && message.type === 'Guild') {
        if (message.data.guildSet) return;
        if (message.data.id === "*") {
          setGuildData(prevGuildData => {
            let gData = {};
            message.data.guilds.forEach(g => {
              gData[g.id] = {
                ...prevGuildData[g.id],
                ...g,
              };
            });
            return gData;
          });
        } else {
          setGuildData((prevData) => ({
            ...prevData,
            [message.data.id]: {
              ...prevData[message.data.id],
              error: false,
              notInGuild: false,
              errorcode: null,
              rerender: true,
              ...message.data,
            }
          }));
          if (message.data.errorcode) {
            if (message.data.errorcode === "ERR_HTTP_404_Discord") {
              addAlert({ title: "Missing Permissions", style: "danger", size: "big", disabled: false, content1: "It seems that you don't have enough permissions to edit this Guild. Please contact us if you think that this in an error." })
            } else if (message.data.errorcode === "ERR_HTTP_404_Bot") {
              //addAlert({ title: "Invite me", style: "primary", size: "big", disabled: false, content1: "It seems that I am not in this Guild, therefor you are not able to edit the config for this Guild. Please contact us if you think that this in an error." })
            } else if (message.data.errorcode === "ERR_HTTP_403_Bot") {
              addAlert({ title: "Error 403: Forbidden", style: "primary", size: "big", disabled: false, content1: "It seems that you have not enough Perms to do that." })
            }
          }
        }
      }
    }
  }, [message, addAlert]);

  return (
    <GuildContext.Provider value={{ guildData, tryAsk, updateGuildData }}>
      {children}
    </GuildContext.Provider>
  );
};

export const useGuildDataContext = (guildId) => {
  const { guildData, tryAsk, updateGuildData } = useContext(GuildContext);

  const getGuildData = () => {
    tryAsk(guildId);
    return guildData[guildId] || null;
  };

  const getAllGuilds = () => {
    let resultArray = [];

    for (let key in guildData) {
      resultArray.push(guildData[key]);
    }

    return resultArray || [];
  }

  return { getGuildData, getAllGuilds, updateGuildData };
};