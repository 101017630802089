import { useGuildDataContext } from "../contextProvider/GuildContext";
import { useLanguage } from "../contextProvider/LanguageContext";
import Card from "./Card";
import './Dashboard.modules.css';

export default function Dashboard({ config }) {
  const { l } = useLanguage();
  const { getAllGuilds } = useGuildDataContext(); // eslint-disable-next-line
  const filteredGuilds = getAllGuilds().filter(g => (g.permissions & (1 << 5)) == Math.floor(2 ** 5))

  return (
    <div className="dashboard_container">
      <div className="dashboard_container_selserver">
        <h2 className="dashboard_container_title">{l("dashboarddisplay.select_server-title")}</h2>
        <p className="dashboard_container_text">{l("dashboarddisplay.select_server-description")}</p>
      </div>
      <div className="dashboard_container_server">
        { filteredGuilds.filter(g => g.notInGuild === false).map(g => <Card name={g.name} link={"/guilds/" + g.id} imagelink={(g.icon === null ? "/images/Default_Icon_Blue.png" : "https://cdn.discordapp.com/icons/" + g.id + "/" + g.icon + ".png")} key={"card." + g.id}/>) }
      </div>
      <div className="dashboard_container_selserver">
        <h2 className="dashboard_container_title">{l("dashboarddisplay.select_server_invite-title")}</h2>
        <p className="dashboard_container_text">{l("dashboarddisplay.select_server_invite-description")}</p>
      </div>
      <div className="dashboard_container_server">
        { filteredGuilds.filter(g => g.notInGuild === true).map(g => <Card name={g.name} link={"/guilds/" + g.id} imagelink={(g.icon === null ? "/images/Default_Icon_Blue.png" : "https://cdn.discordapp.com/icons/" + g.id + "/" + g.icon + ".png")} key={"card." + g.id}/>) }
      </div>
      {/*<div className="dashboard_container_cmdusage">
        <h2 className="dashboard_container_title">{l("dashboarddisplay.command_usage-title")}</h2>
        <p className="dashboard_container_text">{l("dashboarddisplay.command_usage-description")}</p>
        <div className="dashboard_container_btn_active">
          <button type="button" className="dashboard_container_btn_active_btn">
            <span className="dashboard_container_btn_active_btn_text">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M467 45.2A44.45 44.45 0 00435.29 32H312.36a30.63 30.63 0 00-21.52 8.89L45.09 286.59a44.82 44.82 0 000 63.32l117 117a44.83 44.83 0 0063.34 0l245.65-245.6A30.6 30.6 0 00480 199.8v-123a44.24 44.24 0 00-13-31.6zM384 160a32 32 0 1132-32 32 32 0 01-32 32z"></path>
              </svg>
            </span>
            {l("general.Pricing")}
          </button>
          <button type="button" className="dashboard_container_btn">{l("general.Learn_More")}</button>
        </div>
      </div>*/}
    </div>
  )
}