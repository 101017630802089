import { useState } from 'react';
import { useLanguage } from '../../contextProvider/LanguageContext';
import './DropdownForm.modules.css';

export default function DropdownForm({ value, setValue, disabled, options }) {
    const [show, setShow] = useState(false);
    const { l } = useLanguage();
    if(!options) options = [];

    return (
        <div className="form_dropdown">
            <div className="form_dropdown_menu" onClick={() => { if (!disabled) { setShow(!show) } }}>
                <div className="form_dropdown_menu_itemcontainer">
                    <div className="form_dropdown_menu_itemcontainer_text" data-id={value}>{l(options?.find(c => c.value === value)?.lkey) || "ERR_DROPDOWN_NO_KEY"}</div>
                </div>
                <div className="form_dropdown_menu_icon">
                    <hr aria-orientation="vertical" className="form_dropdown_menu_icon_separator" />
                    <div aria-hidden="true" className="form_dropdown_menu_icon_container">
                        <svg viewBox="0 0 24 24" focusable="false" className="form_dropdown_menu_icon_svg">
                            <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z">
                            </path>
                        </svg>
                    </div>
                </div>
            </div>
            {show ? (
                <div className="form_dropdown_items">
                    <div className="form_dropdown_items_container">
                        {options.map(o => (
                            <div tabIndex="-1" className="form_dropdown_items_container_item" key={o.value} onClick={() => { setValue(o.value); setShow(false) }}>
                                {l(o.lkey)}
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
            <input name="channel" type="hidden" value="" />
        </div>
    )
}