import { useLanguage } from "../contextProvider/LanguageContext";
import './UserBody.modules.css';
import SettingsSwitchForm from "./forms/SettingsSwitchForm";
import Badges from "./Badges";
import { useWebSocketContext } from "../contextProvider/WebsocketContext";
import DropdownForm from "./forms/DropdownForm";
import { useUserDataContext } from "../contextProvider/UserContext";

export default function UserBody({ config, user }) {
    const { l } = useLanguage();
    const { sendMessage } = useWebSocketContext();
    const { getUser } = useUserDataContext();
    const authuser = getUser();

    function Button({ title, icon, onClick }) {
        return (
            <button type="button" className="userbody_settings_container_button" onClick={onClick}>
                <span className="userbody_settings_container_button_icon">
                    {icon}
                </span>{title}</button>
        )
    }

    return (
        <div className="userbody">
            <div className="userbody_profile">
                <div className="userbody_profile_banner">
                { user?.banner ? <img src={"https://cdn.discordapp.com/banners/" + user?.id + "/" + user?.banner + ".png"} alt="" style={{ minHeight: "100%", minWidth: "100%", borderRadius: "inherit" }}/> : null}
                </div>
                <div className="userbody_profile_main">
                    <div className="userbody_profile_main_container">
                        <span className="userbody_profile_main_image" data-loaded="">
                            <img src={("https://cdn.discordapp.com/avatars/" + user?.id + "/" + user?.avatar + "?size=512").includes("null") || ("https://cdn.discordapp.com/avatars/" + user?.id + "/" + user?.avatar + "?size=512").includes("undefined") ? "/images/Default_Icon_Blue.png" : "https://cdn.discordapp.com/avatars/" + user?.id + "/" + user?.avatar + "?size=512"} alt="" className="userbody_profile_main_image_img" />
                        </span>
                        <Badges config={config} user={user} />
                    </div>
                    <p className="userbody_profile_main_username">{(user && user.username ? (user.global_name && user.global_name !== "" ? user.global_name + " (@" + user.username + ")" : user?.username) : l("general.Loading"))}</p>
                </div>
            </div>
            <div className="userbody_settings">
                <div className="userbody_settings_title">{l("general.Settings")}</div>
                <div className="userbody_settings_container">
                    {/*{ config.settings.map(s => (s.type === "switch" ? <SettingsSwitchForm setting={s} value={(s.name === "darkmode" ? darkmode : true)} changeValue={(s.name === "darkmode" ? setDarkmode : true)}/> : null)) }
                    <div className="css-12jmmrr">
                        <div className="css-0">
                            <label for="dark-mode" className="chakra-form__label css-o5z4mr">Dark Mode</label>
                            <p className="chakra-text css-1k01w2o">Enables dark theme in order to protect your eyes</p>
                        </div>
                        <label data-checked="" className="chakra-switch css-ghot30">
                            <input className="chakra-switch__input" type="checkbox" id="dark-mode" style={{border: "0px", clip: "rect(0px, 0px, 0px, 0px)", height: "1px", width: "1px", margin: "-1px", padding: "0px", overflow: "hidden", whiteSpace: "nowrap", position: "absolute"}} value="" checked="" />
                            <span data-checked="" aria-hidden="true" className="chakra-switch__track css-k2o3rv">
                                <span className="chakra-switch__thumb css-119530d" data-checked="">
                                </span>
                            </span>
                        </label>
                    </div>
                    <div className="css-12jmmrr">
                        <div className="css-0">
                            <label for="developer-mode" className="chakra-form__label css-o5z4mr">Developer Mode</label>
                            <p className="chakra-text css-1k01w2o">Used for debugging and testing</p>
                        </div>
                        <label data-checked="" className="chakra-switch css-ghot30">
                            <input className="chakra-switch__input" type="checkbox" id="developer-mode" style={{border: "0px", clip: "rect(0px, 0px, 0px, 0px)", height: "1px", width: "1px", margin: "-1px", padding: "0px", overflow: "hidden", whiteSpace: "nowrap", position: "absolute"}} value="" checked="" />
                            <span data-checked="" aria-hidden="true" className="chakra-switch__track css-k2o3rv">
                                <span className="chakra-switch__thumb css-119530d" data-checked="">
                                </span>
                            </span>
                        </label>
                    </div>
                    <div role="group" className="chakra-form-control css-1kxonj9">
                        <div className="css-bxak8j">
                            <label id="field-:r4:-label" for="field-:r4:" className="chakra-form__label css-1m54jns">Language</label>
                            <p className="chakra-text css-1k01w2o">Select your language</p>
                        </div>
                        <div className=" css-79elbk">
                            <span id="react-select-2-live-region" className="css-7pg0cj-a11yText">
                            </span>
                            <span aria-live="polite" aria-atomic="false" aria-relevant="additions text" className="css-7pg0cj-a11yText">
                            </span>
                            <div className=" css-1yt8g8">
                                <div className=" css-6krxh8">
                                    <div className=" css-1yqmgab-singleValue">
                                        <div className="chakra-stack css-84zodg">
                                            <span>English</span>
                                        </div>
                                    </div>
                                    <div className=" css-svnjlw" data-value="">
                                        <input className=" css-146k1ar-input" autocapitalize="none" autocomplete="off" autocorrect="off" id="field-:r4:" spellcheck="false" tabIndex="0" type="text" aria-autocomplete="list" aria-expanded="false" aria-haspopup="true" role="combobox" value="" />
                                    </div>
                                </div>
                                <div className=" css-hfbj6y">
                                    <hr aria-orientation="vertical" className="chakra-divider css-1bykix3" />
                                    <div aria-hidden="true" className=" css-1m720g8">
                                        <svg viewBox="0 0 24 24" focusable="false" className="chakra-icon css-onkibi">
                                            <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <DropdownForm type={"dropdown"} value={user?.config?.language} setValue={v => sendMessage({ type: "UserUpdate", config: { language: v }})} disabled={false} options={[{ value: "de_DE", lkey: "languages.de_DE" }, { value: "en_EN", lkey: "languages.en_EN"}]} />
                    <div className="userbody_settings_container_space">
                    </div>
                    {config.teambuttons.user.map(b => (authuser.checkPerm(authuser.permissions, b.permission) ? <Button title={b.title} icon={b.icon} onClick={() => sendMessage({ type: "UserAction", data: { type: b.action } })} /> : <></>))}
                    <Button title={l("general.Logout")} icon={(<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M160 256a16 16 0 0116-16h144V136c0-32-33.79-56-64-56H104a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h160a56.06 56.06 0 0056-56V272H176a16 16 0 01-16-16zm299.31-11.31l-80-80a16 16 0 00-22.62 22.62L409.37 240H320v32h89.37l-52.68 52.69a16 16 0 1022.62 22.62l80-80a16 16 0 000-22.62z"></path></svg>)} onClick={() => sendMessage({ type: "UserAction", data: { type: "logout" } })} />
                </div>
            </div>
        </div>
    )
}