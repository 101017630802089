import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useWebSocketContext } from './WebsocketContext';
import { useLoginContext } from './LoginContext';

const UserContext = createContext({});

export default UserContext;

export const UserDataProvider = ({ children }) => {
  const { sendMessage, message } = useWebSocketContext();
  const [userData, setUserData] = useState({});
  const askTime = useRef({});

  function tryAsk(userId) {
    let thisAskTime = askTime.current[userId] || new Date(1970, 0, 1, 0, 0, 0, 0);
    if (Math.floor((new Date()).getTime() - new Date(thisAskTime).getTime()) >= 20000) {
      sendMessage({ type: "User", data: { id: userId } })
      askTime.current[userId] = new Date()
    }
  }

  const updateUserData = (userId, newData) => {
    setUserData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        ...newData,
      },
    }));
    sendMessage({ type: "UserUpdate", config: newData });
  };

  useEffect(() => {
    if (message && message.type === 'AuthAccepted') {
      setUserData((prevData) => ({
        ...prevData,
        [message.data.user.id]: {
          ...prevData[message.data.user.id],
          ...message.data.user,
        },
      }));
    } else if (message && message.type === 'User') {
      setUserData((prevData) => ({
        ...prevData,
        [message.data.id]: {
          ...prevData[message.data.id],
          ...message.data,
        },
      }));
    } else if (message && message.type === 'AuthUser') {
      setUserData((prevData) => ({
        ...prevData,
        [message.data.id]: {
          ...prevData[message.data.id],
          ...message.data,
        },
      }));
    }
  }, [message]);

  return (
    <UserContext.Provider value={{ userData, tryAsk, updateUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserDataContext = (userId) => {
  const { userData, tryAsk, updateUserData } = useContext(UserContext);
  const { getAuthUser } = useLoginContext();

  const roles = {
    "api.roles.owner": [
      "api.*",
    ],
    "api.roles.admin": [
      "api.*",
    ],
    "api.roles.developer": [
      "api.debug.*",
    ],
    "api.roles.moderator": [
      "api.user.fetch",
      "api.user.banTemp",
      "api.guilds.fetch",
      "api.guilds.banTemp",
      "api.team.roles.show",
      "api.team.permissionoverwrites.show",
    ],
    "api.roles.supporter": [
      "api.user.fetch",
      "api.guilds.fetch",
      "api.team.roles.show",
      "api.team.permissionoverwrites.show",
    ],
  };
  const permissions = [
    "api.*",

    "api.user.*",
    "api.user.fetch",
    "api.user.edit",
    "api.user.message",
    "api.user.editRaw!x",
    "api.user.delete!x",
    "api.user.banTemp",
    "api.user.ban",
    "api.user.removeRatelimitTemp",
    "api.user.removeRatelimit!x",
    "api.user.bypassRatelimitTemp",
    "api.user.bypassRatelimit!x",

    "api.guilds.*",
    "api.guilds.fetch",
    "api.guilds.edit",
    "api.guilds.message",
    "api.guilds.messageOwner",
    "api.guilds.editRaw!x",
    "api.guilds.delete!x",
    "api.guilds.banTemp",
    "api.guilds.ban",
    "api.guilds.removeRatelimitTemp",
    "api.guilds.removeRatelimit!x",
    "api.guilds.bypassRatelimitTemp",
    "api.guilds.bypassRatelimit!x",

    "api.statussystem.*",
    "api.statussystem.setstatus.*",
    "api.statussystem.setstatus.online",
    "api.statussystem.setstatus.warn",
    "api.statussystem.setstatus.error",
    "api.statussystem.setstatus.down",

    "api.team.*",
    "api.team.roles.*",
    "api.team.roles.add",
    "api.team.roles.show",
    "api.team.roles.remove",

    "api.team.permissionoverwrites.*",
    "api.team.permissionoverwrites.add!x",
    "api.team.permissionoverwrites.show",
    "api.team.permissionoverwrites.remove!x",

    "api.team.bypassLog!x",

    "api.developer.*",
    "api.developer.api.*!x",
    "api.developer.api.start",
    "api.developer.api.restart",
    "api.developer.api.stop",

    "api.developer.dashboard.*!x",
    "api.developer.dashboard.start",
    "api.developer.dashboard.restart!u",
    "api.developer.dashboard.stop",

    "api.developer.statussystem.*!x",
    "api.developer.statussystem.start",
    "api.developer.statussystem.restart",
    "api.developer.statussystem.stop",

    "api.developer.debug.*",
    "api.developer.debug.user",
    "api.developer.debug.guilds",

    "api.teams.*!s",
]
  const checkPerm = (userperms={}, permission) => {

    function isCoveredByParent(permission, parent) {
      if (parent.endsWith('*')) {
        const parentPrefix = parent.slice(0, -1);
        return permission.startsWith(parentPrefix);
      }
      return false;
    }

    function isOverwritten(permission) {
      return userperms?.overwrites?.some(ow => ow.permission === permission.replace("!x", "").replace("!u", "").replace("!s", "") && ow.overwrite);
    }

    function requiresOverwrite(permission) {
      if(!permission) { console.log(permission); return false; }
      return permission.endsWith('!x');
    }

    let userPermissions = new Set();
    userperms?.roles?.forEach(role => {
      if (roles[role]) {
        roles[role].forEach(perm => userPermissions.add(perm));
      }
    });

    if (userPermissions.has(permission) || [...userPermissions].some(perm => isCoveredByParent(permission, perm))) {
      if (requiresOverwrite(permissions.find(p => p.startsWith(permission)))) {
        return isOverwritten(permission);
      }
      let parent = "";
      let parents = permission.split(".")
      if (parents.length === 2) parent = parents[0] + ".*"
      else if (parents.length === 3) parent = parents[0] + "." + parents[1] + ".*"
      else if (parents.length === 4) parent = parents[0] + "." + parents[1] + "." + parents[2] + ".*"
      else if (parents.length === 5) parent = parents[0] + "." + parents[1] + "." + parents[2] + "." + parents[3] + ".*"
      else if (parents.length === 6) parent = parents[0] + "." + parents[1] + "." + parents[2] + "." + parents[3] + "." + parents[4] + ".*"
      else if (parents.length === 7) parent = parents[0] + "." + parents[1] + "." + parents[2] + "." + parents[3] + "." + parents[4] + "." + parents[5] + ".*"
      if (isCoveredByParent(permission, parent) && requiresOverwrite(permissions.find(p => p.startsWith(parent)))) {
        return isOverwritten(permission) || isOverwritten(parent.replace("!x", ""));
      }
      return true;
    }

    return false;
  }

  const getUserData = () => {
    return userData[userId] || { id: null, username: "Loading...", icon: null };
  };

  const getUser = () => {
    if(userId && getAuthUser() !== "0" && checkPerm(userData[getAuthUser()].permissions, "api.user.fetch")) { tryAsk(userId) }
    if(userId) return { ...userData[userId || "0"], checkPerm } || {};
      else return { ...userData[getAuthUser() || "0"], checkPerm } || {};
  }

  const getAllUser = () => {
    let resultArray = [];

    for (let key in userData) {
      resultArray.push(userData[key]);
    }

    return resultArray || [];
  }

  return { getUserData, getUser, getAllUser, checkPerm, updateUserData };
};