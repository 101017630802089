module.exports = {
    general: {
        Main: "Main",
        Dashboard: "Dashboard",
        Guilds: "Guilds",
        Profile: "Profile",
        Settings: "Settings",
        Vanity: "Shorl.Me-Vanity",
        Config: "Config",
        Features: "Features",
        Searching: "Searching...",
        Loading: "Loading...",
        Login: "Login",
        Logout: "Logout",
        Enable: "Enable",
        Disable: "Disable",
        Save: "Save",
        Save_Changes: "Save Changes",
        Discard: "Discard",
        Discard_Changes: "Discard Changes",
        Pricing: "Pricing",
        Learn_More: "Learn More",
    },
    pages: {
        "Main-description": "Simplica's Dashboard - Configure Your Multipurpose Discord Bot!",
        "Dashboard-description": "Simplica's Dashboard - Configure Your Multipurpose Discord Bot!",
        "Guilds-description": "Choose a Guild and edit their configuration or invite me to more. You choose!",
        "{guildName}-description": "Configure our Features for {guildName}",
        "Features-description": "Configure our Features for {guildName}",
        "{featureName}-description": " Configure the {featureName} for {guildName}",
        "Settings-description": "Configure the Settings for {guildName}",
        "Vanity-description": "Configure your own Vanity-Url",
        "User-description": "Edit your Profile or how you wanna use Simplica",
        "Profile-description": "Edit your Profile or how you wanna use Simplica",
    },
    features: {
        "settings-title": "Settings",
        "settings-description": "Settings",
        "settings-language-title": "Guild Language",
        "settings-language-description": "Set the primary Language for your Guild. (User-specific functions will be in User's language.)",
        "settings-language-placeholder": "Choose the primary Language (Default: English)",
        "settings-language-de_DE": "Deutsch",
        "settings-language-en_EN": "English",
        "vanity-title": "Your custom Shorl.Me-Vanity",
        "vanity-description": "Get your custom Vanity-Url with our System Shorl.Me",
        "vanity-vanity-title": "Vanity-URLs",
        "vanity-vanity-description": "Set or change your Vanity-Url",
        "welcome_system-title": "Welcome-System",
        "welcome_system-description": "Configure our Welcome-System",
        "welcome_system-message.enabled-title": "Enable Message",
        "welcome_system-message.enabled-description": "Enable sending a Message",
        "welcome_system-sticker.enabled-title": "Enable Sticker",
        "welcome_system-sticker.enabled-description": "Enable sending a Sticker",
        "welcome_system-message.channel-title": "Channel",
        "welcome_system-message.channel-description": "Channel",
        "welcome_system-message.channel-placeholder": "Select a channel",
        "welcome_system-message.channel-notFoundPlaceholder": "Channel not found",
        "welcome_system-message.message-title": "Message",
        "welcome_system-message.message-description": "The Message to greet new Members",
        "welcome_system-message.message-placeholder": "Hi",
        "leave_system-title": "Leave-System",
        "leave_system-description": "Configure our Leave-System",
        "leave_system-message.enabled-title": "Enable Message",
        "leave_system-message.enabled-description": "Enable sending a Message",
        "leave_system-message.channel-title": "Channel",
        "leave_system-message.channel-description": "Channel",
        "leave_system-message.channel-placeholder": "Select a channel",
        "leave_system-message.channel-notFoundPlaceholder": "Channel not found",
        "leave_system-message.message-title": "Message",
        "leave_system-message.message-description": "The Message to say goodbye",
        "leave_system-message.message-placeholder": "Bye",
    },
    badges: {
        "staff-title": "Discord Staff",
        "partner-title": "Discord Partner",
        "hypesquad-title": "HypeSquad Events",
        "bug_hunter_1-title": "Bug Hunter Level 1",
        "mfa_sms-title": "SMS as MFA-Source",
        "premium_promo_dismissed-title": "Dismissed Premium Promotion",
        "hypesquad_bravery-title": "HypeSquad Bravery",
        "hypesquad_brilliance-title": "HypeSquad Brilliance",
        "hypesquad_balance-title": "HypeSquad Balance",
        "premium_early_supporter-title": "Premium Early Supporter (Early Nitro)",
        "team_pseudo_user-title": "Team Pseudo User",
        "internal_application-title": "Internal Application",
        "system-title": "Discord System",
        "has_unread_urgent_messages-title": "Unread urgent Messages",
        "bug_hunter_2-title": "Bug Hunter Level 2",
        "underage_deleted-title": "Deleted underage Account",
        "verified_bot-title": "Verified Bot",
        "verified_developer-title": "Verified Developer",
        "certified_moderator-title": "Certfied Moderator",
        "bot_http_interactions-title": "Bot HTTP Interactions",
        "spammer-title": "Flagged for Spamming",
        "disable_premium-title": "Nitro Features disabled",
        "active_developer-title": "Active Developer",
        "high_global_rate_limit-title": "High Global Rate Limit",
        "deleted-title": "Deleted Account",
        "disabled_suspicious_activity-title": "Disabled Suspicious Activity Account",
        "self_deleted-title": "Self-Deleted Account",
        "premium_discriminator-title": "Premium Discriminator",
        "used_desktop_client-title": "Used Desktop Client",
        "used_web_client-title": "Used Web Client",
        "used_mobile_client-title": "Use Mobile Client",
        "disabled-title": "Disabled Account",
        "verified_email-title": "Verified Email",
        "quarantined-title": "Quarantined Account",
        "collaborator-title": "Discord Collaborator",
        "restricted_collaborator-title": "Restricted Discord Collaborator",
    },
    dashboarddisplay: {
        "title": "Dashboard",
        "description": "Dashboard",
        "select_server-title": "Select Server",
        "select_server-description": "Select the server to configure",
        "select_server_invite-title": "Invite me",
        "select_server_invite-description": "Invite me to configure these Servers",
        "command_usage-title": "Command Usage",
        "command_usage-description": "Use of commands of your server",
    },
    guilddisplay: {
        "panel-title": "Getting started",
        "panel-description": "Configure our bot to make your server fantastic"
    },
    languages: {
        "de_DE": "Deutsch",
        "en_EN": "English"
    }
}