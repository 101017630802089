import { useEffect, useMemo, useRef, useState } from 'react';
import Navigation from '../components/Navigation';
import FeatureSidebar from '../components/FeatureSidebar';
import { useParams } from 'react-router-dom';
import { useGuildDataContext } from '../contextProvider/GuildContext';
import FeatureBody from '../components/FeatureBody';
import { checkCookie } from '../tools/CookieFunctions';
import { useLoginContext } from '../contextProvider/LoginContext';
import Footer from '../components/Footer';
import ErrorSystem from '../components/ErrorSystem';

export default function GuildFeature({ config }) {
  const [showSmallSidebar, setShowSmallSidebar] = useState(false);
  let { guildId, featureId } = useParams();
  const { getGuildData, getAllGuilds } = useGuildDataContext(guildId || "0");
  let guildData = getGuildData() || config.standardGuild({ id: guildId, name: getAllGuilds().find(g => g.id === guildId)?.name, icon: getAllGuilds().find(g => g.id === guildId)?.icon })
  const { isLoggedIn } = useLoginContext();
  const [, setRender] = useState(false);
  const cookies = useRef({
    session: checkCookie('session'),
    token: checkCookie('token'),
    hasToken: checkCookie('hasToken')
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newCookies = {
        session: checkCookie('session'),
        token: checkCookie('token'),
        hasToken: checkCookie('hasToken')
      };

      if (
        newCookies.session !== cookies.current.session ||
        newCookies.token !== cookies.current.token ||
        newCookies.hasToken !== cookies.current.hasToken
      ) {
        cookies.current = newCookies;
        setRender(prev => !prev);
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const memoizedComponent = useMemo(() => {
    function enableSmallSidebar() { setShowSmallSidebar(true) };
    function disableSmallSidebar() { setShowSmallSidebar(false) };
    return (
      <div className="main" key={"guildfeature." + guildData.id}>
        <FeatureSidebar config={config} id={guildData.id} key={"featuressidebar.guildfeature." + guildData.id} />
        {guildData.rerender && (
          <>
            <div className="main_body">
              <Navigation config={config} showSmallSidebar={enableSmallSidebar} />
              <div className="main_body_container">
                <ErrorSystem guildData={guildData} guildId={guildId} page={"Guild"} cookies={cookies} config={config} isLoggedIn={isLoggedIn} children={(<FeatureBody config={config} guildData={guildData} id={guildData.id} featureId={featureId} />)} />
              </div>
              <Footer />
            </div>
            { /*<SmallFeatureSidebar config={config} show={showSmallSidebar} exit={disableSmallSidebar} />*/}
          </>
        )}
      </div>
    );
  }, [config, guildData, featureId, isLoggedIn, cookies, showSmallSidebar]);

  return memoizedComponent;
}