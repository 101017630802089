import { useState } from "react";
import Banner from "./Banner";
import Loading from "./Loading";
import Navigator from "./Navigator";
import { checkCookie, getCookie, setCookie } from "../tools/CookieFunctions";

export default function ErrorSystem({ guildData, guildId, page, cookies, config, isLoggedIn, children }) { // eslint-disable-next-line
    const [_, setRender] = useState(false);

    window.rerender = () => {
        setRender(prevData => { return !prevData })
    }

    if (!checkCookie("loginState")) {
        function generateRandomString(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }
        setCookie("loginState", generateRandomString(12), 0.00694, "Strict")
    } else {
        setCookie("loginState", getCookie("loginState"), 0.00694, "Strict")
    }

    function checkLogin() {
        if (cookies.current.session && cookies.current.token && cookies.current.hasToken && isLoggedIn()) return true;
        else if (cookies.current.session && cookies.current.token && cookies.current.hasToken && isLoggedIn() === null) return null;
        else return false;
    }
    if (page === "Guild") {
        if (checkLogin() === null) return (<Loading />); else if (checkLogin() === false) return (<Navigator to={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=${process.env.REACT_APP_SCOPE}&state=${getCookie("loginState")}`}></Navigator>);
        if (guildData?.error === true && guildData?.notInGuild === true) {
            return (<div className="guildbody"><div className="guildbody_container"><Banner title={"404 - Guild not found"} content={"It seems that I'm not a member of this Guild. To edit the settings you would need to invite me."} buttons={[{ title: "pls Invite", icon: config.getIcon("Settings"), link: (process.env.REACT_APP_BOT_INVITE).replace("{client_id}", process.env.REACT_APP_CLIENT_ID).replace("{guildParam}", "&guild_id=" + guildData?.id) }]} /></div></div>)
        } else if ((guildData?.error === true && guildData?.errorcode === "ERR_HTTP_404_Database") || !guildData?.config?.settings?.id) {
            return (<div className="guildbody"><div className="guildbody_container"><Banner title={"Database Error"} content={"It seems that we could not find your server in our database. Please try executing a configuration command such as /language in the server. If the error persists, please contact our support with the ServerID (" + guildId + ") and the error 404_Database."} colorScheme={"default"} buttons={[]} /></div></div>)
        } else if (guildData?.error === true) {
            return (<div className="guildbody"><div className="guildbody_container"><Banner title={"Error"} content={"There was an unexpected Error.\n Errorcode: " + guildData?.errorcode} colorScheme={"default"} buttons={[]} /></div></div>)
        }
    } else if (page === "GuildFeature" || page === "GuildSettings") {
        if (checkLogin() === null) return (<Loading />); else if (checkLogin() === false) return (<Navigator to={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=${process.env.REACT_APP_SCOPE}&state=${getCookie("loginState")}`}></Navigator>);
        if (guildData?.error === true && guildData?.notInGuild === true) {
            return (<div className="featurebody"><div className="featurebody_container"><Banner title={"404 - Guild not found"} content={"It seems that I'm not a member of this Guild. To edit the settings you would need to invite me."} buttons={[{ title: "Invite", icon: config.getIcon("Settings"), link: (process.env.REACT_APP_BOT_INVITE).replace("{client_id}", process.env.REACT_APP_CLIENT_ID).replace("{guildParam}", "&guild_id=" + guildData?.id) }]} /></div></div>)
        } else if ((guildData?.error === true && guildData?.errorcode === "ERR_HTTP_404_Database") || !guildData?.config?.settings?.id) {
            return (<div className="featurebody"><div className="featurebody_container"><Banner title={"Database Error"} content={"It seems that we could not find your server in our database. Please try executing a configuration command such as /language in the server. If the error persists, please contact our support with the ServerID (" + guildId + ") and the error 404_Database."} colorScheme={"default"} buttons={[]} /></div></div>)
        } else if (guildData?.error === true) {
            return (<div className="featurebody"><div className="featurebody_container"><Banner title={"Error"} content={"There was an unexpected Error.\n Errorcode: " + guildData?.errorcode} colorScheme={"default"} buttons={[]} /></div></div>)
        }
    } else if (page === "Home") {
        if (checkLogin() === null) return (<Loading />); else if (checkLogin() === false) return (<Navigator to={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=${process.env.REACT_APP_SCOPE}&state=${getCookie("loginState")}`}></Navigator>);
    } else if (page === "UserProfile") {
        if (checkLogin() === null) return (<Loading />); else if (checkLogin() === false) return (<Navigator to={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=${process.env.REACT_APP_SCOPE}&state=${getCookie("loginState")}`}></Navigator>);
    }

    return (
        <>{children}</>
    )
}