import SidebarCard from "./SidebarCard";
import SidebarNavigationItem from "./SidebarNavigationItem";
import SidebarProfile from "./SidebarProfile";
import "./Sidebar.modules.css";
import { useLanguage } from "../contextProvider/LanguageContext";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { useGuildDataContext } from "../contextProvider/GuildContext";
import { useUserDataContext } from "../contextProvider/UserContext";
import SidebarCardLoading from "./SidebarCardLoading";

export default function Sidebar({ config, loading }) {
    const [ value, setValue ] = useState("");
    const { pathname } = useLocation();
    const { guildId } = useParams();
    const { l } = useLanguage();
    const { getAllGuilds } = useGuildDataContext();
    const { getUser } = useUserDataContext();
    const user = getUser(); // eslint-disable-next-line
    const filteredGuilds = getAllGuilds().filter(g => (g.permissions & (1 << 5)) == Math.floor(2 ** 5))
    
    return (
        <div className="sidebar">
            <div style={{ opacity:1, transform: "none"}}>
                <div className="sidebar_header">
                    <h2 className="sidebar_header_title">{config.name}</h2>
                </div>
                <div className="sidebar_body">
                    <div className="sidebar_body_navigationitems">
                        { config.navigationItems.map(i => <SidebarNavigationItem name={i.name} link={i.link} svgicon={i.svgicon} enabled={i.enabledPaths.includes(pathname)} key={"sidebarnavigationitem." + i.link} />)}
                    </div>
                    <div className="sidebar_body_searchbar">
                        <div className="sidebar_body_searchbar_body">
                            <div className="sidebar_body_searchbar_body_icon">
                                <button type="button" className="sidebar_body_searchbar_body_icon_button" aria-label="search">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" focusable="false" className="sidebar_body_searchbar_body_icon_svg" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                                    </svg>
                                </button>
                            </div>
                            <input placeholder={l("general.Searching")} className="sidebar_body_searchbar_body_input" value={value} onChange={e => setValue(e.target.value)} /></div>
                    </div>
                    <div className="sidebar_body_cards">
                    { loading ? ( (value === "" ? (filteredGuilds.filter(g => g.notInGuild === false).map(g => <SidebarCard name={g.name} link={"/guilds/" + g.id} imagelink={"https://cdn.discordapp.com/icons/" + g.id + "/" + g.icon + ".png"} active={(g.id === guildId)} key={"sidebarcard." + g.id} />)) : filteredGuilds.filter(g => g.notInGuild === false).filter(g => String(g.name.toLowerCase()).includes(value.toLowerCase()) || g.id.includes(value)).map(g => <SidebarCard name={g.name} link={"/guilds/" + g.id} imagelink={"https://cdn.discordapp.com/icons/" + g.id + "/" + g.icon + ".png"} active={(g.id === guildId)} key={"sidebarcard." + g.id} />) )) : <><SidebarCardLoading/><SidebarCardLoading/><SidebarCardLoading/><SidebarCardLoading/><SidebarCardLoading/></>}
                    { loading ? ( (value === "" ? (filteredGuilds.filter(g => g.notInGuild === true).map(g => <SidebarCard name={g.name} link={"/guilds/" + g.id} imagelink={"https://cdn.discordapp.com/icons/" + g.id + "/" + g.icon + ".png"} active={(g.id === guildId)} key={"sidebarcard." + g.id} />)) : filteredGuilds.filter(g => g.notInGuild === true).filter(g => String(g.name.toLowerCase()).includes(value.toLowerCase()) || g.id.includes(value)).map(g => <SidebarCard name={g.name} link={"/guilds/" + g.id} imagelink={"https://cdn.discordapp.com/icons/" + g.id + "/" + g.icon + ".png"} active={(g.id === guildId)} key={"sidebarcard." + g.id} />) )) : null}
                    </div>
                </div>
            </div>
            <div className="sidebarspace"></div>
            <SidebarProfile config={config} user={user} />
        </div>
    )
}