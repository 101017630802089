import { useLanguage } from '../contextProvider/LanguageContext';
import './Loading.modules.css';

export default function Loading() {
    const { l } = useLanguage();

    return (
        <div className="loading">
            <div className="loading_container">
                <div className="loading_animation">
                    <span className="loading_animation_text">{l("general.Loading")}</span>
                </div>
                <p className="loading_text">{l("general.Loading")}</p>
            </div>
        </div>
    )
}

