import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useAlertDataContext } from './AlertContext';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [message, setMessage] = useState(null);
  const websocketRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);
  const reconnectInterval = useRef(1000);
  const messageQueueRef = useRef([]);
  const messageAuthQueueRef = useRef([]);
  const connectRef = useRef(0);
  const { addAlert } = useAlertDataContext();
  const addAlertRef = useRef(addAlert);

  const connectWebSocket = useCallback(() => {
    const scheduleReconnect = () => {
      reconnectAttemptsRef.current += 1;
      reconnectInterval.current = 1000;
  
      setTimeout(() => {
        connectWebSocket();
      }, reconnectInterval.current);
    };

    if (!websocketRef.current || websocketRef.current.readyState === WebSocket.CLOSED) {
      websocketRef.current = new WebSocket(process.env.REACT_APP_API_URL);

      websocketRef.current.onopen = () => {
        console.log('[WebSocket] Connected');
        reconnectAttemptsRef.current = 0;

        while (messageQueueRef.current.length > 0) {
          const msg = messageQueueRef.current.shift();
          websocketRef.current.send(JSON.stringify(msg));
        }

        reconnectInterval.current = 1000;
      };

      websocketRef.current.onmessage = (event) => {
        const msg = JSON.parse(event.data);
        console.log(msg)
        if (msg.type === "AuthAccepted") {
          console.log("[Websocket] Connection Authorized")
          while (messageAuthQueueRef.current.length > 0) {
            const msg = messageAuthQueueRef.current.shift();
            websocketRef.current.send(JSON.stringify(msg));
          }
        } else if(msg.type === "askForAuthentification") {
          connectRef.current += 1;
          if(connectRef.current > 1) addAlertRef.current({ title: "API Connected", style: "success", size: "big", disabled: false, content1: "We are back connected to our API." })
        }
        setMessage(msg);
      };

      websocketRef.current.onclose = () => {
        if(connectRef.current > 0) addAlertRef.current({ title: "API Disconnected", style: "danger", size: "big", disabled: false, content1: "We got disconnected from our API." })
        console.log('[WebSocket] Disconnected');
        scheduleReconnect();
      };

      websocketRef.current.onerror = (error) => {
        //if(connectRef.current > 0) addAlertRef.current({ title: "API Disconnected", style: "danger", size: "big", disabled: false, content1: "We got disconnected from our API." })
        //console.log('[WebSocket] Connection error');
        websocketRef.current.close();
        scheduleReconnect();
      };
    }
  }, [addAlertRef]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, [connectWebSocket]);

  const sendMessage = (msg) => {
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
      websocketRef.current.send(JSON.stringify(msg));
    } else {
      if (msg.type === "Guild" || msg.type === "GuildAction" || msg.type === "User" || msg.type === "UserAction") {
        messageAuthQueueRef.current.push(msg);
      } else {
        messageQueueRef.current.push(msg);
      }
    }
  };

  return (
    <WebSocketContext.Provider value={{ message, sendMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};