import { createContext, useContext, useState } from 'react';

const ThemeContext = createContext({});

export default ThemeContext;

export const ThemeProvider = ({ document, children }) => {
  const [theme, setTheme] = useState("Blue");

  const updateTheme = (newTheme) => {
    setTheme(newTheme || "Blue")
  }

  const root = document.getElementById("root");
  for (let property in (require("../themes/" + theme + "Theme.js") || require("../themes/BlueTheme.js"))) {
    root.style.setProperty(property, (require("../themes/" + theme + "Theme.js") || require("../themes/BlueTheme.js"))[property]);
  }

  document.body.style.color = (require("../themes/" + theme + "Theme.js") || require("../themes/BlueTheme.js"))["--textcolor"]
  document.body.style.backgroundColor = (require("../themes/" + theme + "Theme.js") || require("../themes/BlueTheme.js"))["--background1"]

  return (
    <ThemeContext.Provider value={{ document, theme, updateTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const { document, theme, updateTheme } = useContext(ThemeContext);

  const getTheme = () => {
    return require("../themes/" + theme + "Theme.js") || require("../themes/BlueTheme.js");
  }

  const toggleTheme = () => {
    const themeIds = [
      {
        theme: "Blue",
        id: 0
      },
      {
        theme: "Red",
        id: 1
      }
    ]

    const newTheme = themeIds.find(t => t.id === themeIds.find(z => z.theme === theme).id + 1)?.theme || "Blue";

    updateTheme(newTheme)

    const root = document.getElementById("root");
    for (let property in getTheme()) {
      root.style[property] = getTheme()[property];
    }
    document.body.style.color = (require("../themes/" + newTheme + "Theme.js") || require("../themes/BlueTheme.js"))["--textcolor"]
    document.body.style.backgroundColor = (require("../themes/" + newTheme + "Theme.js") || require("../themes/BlueTheme.js"))["--background1"]
  }

  return { getTheme, toggleTheme, updateTheme };
};