import Dashboard from '../components/Dashboard';
import Navigation from '../components/Navigation';
import Sidebar from '../components/Sidebar';
import SmallSidebar from '../components/SmallSidebar';
import { useEffect, useMemo, useRef, useState } from 'react';
import { checkCookie } from '../tools/CookieFunctions';
import { useLoginContext } from '../contextProvider/LoginContext';
import Footer from '../components/Footer';
import ErrorSystem from '../components/ErrorSystem';

export default function Home({ config }) {
  const [showSmallSidebar, setShowSmallSidebar] = useState(false);
  const { isLoggedIn } = useLoginContext();
  const [, setRender] = useState(false);
  const cookies = useRef({
    session: checkCookie('session'),
    token: checkCookie('token'),
    hasToken: checkCookie('hasToken')
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newCookies = {
        session: checkCookie('session'),
        token: checkCookie('token'),
        hasToken: checkCookie('hasToken')
      };

      if (
        newCookies.session !== cookies.current.session ||
        newCookies.token !== cookies.current.token ||
        newCookies.hasToken !== cookies.current.hasToken
      ) {
        cookies.current = newCookies;
        setRender(prev => !prev);
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const memoizedComponent = useMemo(() => {
    function enableSmallSidebar() { setShowSmallSidebar(true) };
    function disableSmallSidebar() { setShowSmallSidebar(false) };
    return (
      <div className="main" key={"home"}>
        <Sidebar config={config} key={"sidebar.home"} loading={(cookies.current.session && cookies.current.token && cookies.current.hasToken && isLoggedIn())} />
        <div className="main_body">
          <Navigation config={config} showSmallSidebar={enableSmallSidebar} />
          <div className="main_body_container">
            <ErrorSystem guildData={{}} guildId={{}} page={"Home"} cookies={cookies} config={config} isLoggedIn={isLoggedIn} children={(<Dashboard config={config} />)} />
          </div>
          <Footer />
        </div>
        <SmallSidebar config={config} show={showSmallSidebar} exit={disableSmallSidebar} />
      </div>
    );
  }, [config, isLoggedIn, cookies, showSmallSidebar]);

  return memoizedComponent;
}