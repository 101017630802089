import { useNavigate } from "react-router-dom";
import { useLanguage } from "../contextProvider/LanguageContext";
import FeatureCard from "./FeatureCard";
import './GuildBody.modules.css';
import Banner from "./Banner";

export default function GuildBody({ config, id, user, guildData }) {
  const { l } = useLanguage();

  return (
    <div className="guildbody">
      <div className="guildbody_container">
        <div className="guildbody_panel">
          <Banner title={l("guilddisplay.panel-title")} content={l("guilddisplay.panel-description")} colorScheme="default" buttons={[{ title: l("general.Settings"), icon: config.getIcon("Settings"), link: "/guilds/" + id + "/settings" }]} />
          {user.checkPerm(user.permissions, "api.team.team") ? <><div style={{ height: "20px" }}></div><Banner title={"Team-Panel"} content={""} colorScheme="team" buttons={config.teambuttons.guild.filter(b => (user.checkPerm(user.permissions, b.permission)))} /></> : <></>}
        </div>
        <div className="guildbody_features">
          <h2 className="guildbody_features_header">
            {l("general.Features")}
          </h2>
          <div className="guildbody_features_body">
            {config.features.map(f => (f.name.startsWith("settings") ? <></> : <FeatureCard config={config} id={id} thisFeature={f} guildfeature={guildData.config[f.name] || null} key={"featurecard." + f.name} />))}
          </div>
        </div>
      </div>
    </div>
  )
}