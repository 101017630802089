import { createContext, useContext, useRef, useState } from 'react';

const AlertContext = createContext({});

export default AlertContext;

export const AlertProvider = ({ children }) => {
  const alert = useRef([]);
  const [render, setRender] = useState(false);

  const addAlert = (newAlert) => {
    if(alert.current.find((al) => al.title === newAlert.title && al.size === newAlert.size && al.style === newAlert.style && al.content1 === newAlert.content1 && al.content2 === newAlert.content2 && al.disabled === false)) return;
    alert.current.push(newAlert);
    setRender(!render)
  };

  const disableAlert = (a) => {
      const index = alert.current.findIndex((al) => al.title === a.title && al.size === a.size && al.style === a.style && al.content1 === a.content1 && al.content2 === a.content2 && al.disabled === false);
      
      if (index !== -1) {
        alert.current[index].disabled = true;
        setRender(!render)
      }
  };

  return (
    <AlertContext.Provider value={{ alert, addAlert, disableAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlertDataContext = () => {
  const { alert, addAlert, disableAlert } = useContext(AlertContext);

  const getAlerts = () => {
    return alert.current || [];
  };

  return { getAlerts, addAlert, disableAlert };
};