import { useNavigate } from 'react-router-dom';
import './Card.modules.css';

export default function Card({ name, link, imagelink }) {
    const navigate = useNavigate();
    return (
        <button className="a card_main" onClick={() => { navigate(link) }} style={{ cursor: "pointer" }}>
            <div className="card_main_header">
                <span className="card_main_header_avatar" data-loaded="">
                    <img src={(imagelink.includes("/null") ? "/images/Default_Icon_Grey.png" : imagelink)} alt="" className="card_main_header_avatar_img" />
                </span>
                <p className="">{name}</p>
            </div>
        </button>
    )
}