import { useLocation, useNavigate, useParams } from "react-router-dom"
import NavigationItem from "./NavigationItem";
import './Navigation.modules.css';
import { useLanguage } from "../contextProvider/LanguageContext";
import { useGuildDataContext } from "../contextProvider/GuildContext";
import { useThemeContext } from "../contextProvider/ThemeContext";
import { useUserDataContext } from "../contextProvider/UserContext";
import { useAlertDataContext } from "../contextProvider/AlertContext";

export default function Navigation({ config, showSmallSidebar }) {
    const { l } = useLanguage();
    const { pathname } = useLocation();
    const { guildId, userId, featureId } = useParams();
    const { getAllGuilds } = useGuildDataContext();
    const { toggleTheme } = useThemeContext();
    const { getUser, getAllUser } = useUserDataContext();
    const user = getUser();
    const navigate = useNavigate();
    const { addAlert } = useAlertDataContext();
    const page = config.pages.find(p => p.path.replace("{guildId}", `${guildId}`).replace("{userId}", `${userId}`).replace("{featureId}", `${featureId}`) === pathname || p.path.replace("{guildId}", `${guildId}`).replace("{userId}", `${userId}`).replace("{featureId}", `${featureId}`) + "/" === pathname) || { name: "Main", path: "/user/home", navpath: "Main", icon: (<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M261.56 101.28a8 8 0 00-11.06 0L66.4 277.15a8 8 0 00-2.47 5.79L63.9 448a32 32 0 0032 32H192a16 16 0 0016-16V328a8 8 0 018-8h80a8 8 0 018 8v136a16 16 0 0016 16h96.06a32 32 0 0032-32V282.94a8 8 0 00-2.47-5.79z"></path><path d="M490.91 244.15l-74.8-71.56V64a16 16 0 00-16-16h-48a16 16 0 00-16 16v32l-57.92-55.38C272.77 35.14 264.71 32 256 32c-8.68 0-16.72 3.14-22.14 8.63l-212.7 203.5c-6.22 6-7 15.87-1.34 22.37A16 16 0 0043 267.56L250.5 69.28a8 8 0 0111.06 0l207.52 198.28a16 16 0 0022.59-.44c6.14-6.36 5.63-16.86-.76-22.97z"></path></svg>), title: "TPG Dashboard", description: "Simplica's Dashboard - Powered by TPG-Development" }
    const way = page.navpath.split("->") || ["Main"];

    return (
        <div className="main_body_navigation">
            <div className="main_body_navigation_container">
                <div className="main_body_navigation_conatiner_navigation">
                    <nav aria-label="breadcrumb" className="main_body_navigation_conatiner_navigation_nav">
                        <ol className="main_body_navigation_conatiner_navigation_nav_ol">
                            { way.map(w => (<NavigationItem name={config.pages.find(p => p.name === w).name.replace("{guildName}", (getAllGuilds().find(g => g.id === guildId) ? getAllGuilds().find(g => g.id === guildId).name : "Loading")).replace("{userName}", (getAllUser().find(g => g.id === userId) ? (getAllUser().find(g => g.id === userId).global_name ? getAllUser().find(g => g.id === userId).global_name : getAllUser().find(g => g.id === userId).username) : "Loading")).replace("{featureName}", l("features." + featureId + "-title"))} path={config.pages.find(p => p.name === w).path.replace("{guildId}", guildId).replace("{userId}", userId).replace("{featureId}", featureId)} 
                            
                            icon={
                                    (w === "{guildName}" ? 
                                        (
                                            <img style={{width: "1em", height: "1em", display: "inline-block", lineHeight: "1em", flexShrink: "0", borderRadius: "9999px"}} alt="" src={(getAllGuilds().find(g => g.id === guildId) && getAllGuilds().find(g => g.id === guildId).icon !== null ? "https://cdn.discordapp.com/icons/" + guildId + "/" + getAllGuilds().find(g => g.id === guildId).icon + ".png" : "/images/Default_Icon_Blue.png")}></img>
                                        )
                                : 
                                    (w === "{userName}" ? 
                                        (
                                            <img style={{width: "1em", height: "1em", display: "inline-block", lineHeight: "1em", flexShrink: "0", borderRadius: "9999px"}} alt="" src={(getAllUser().find(g => g.id === userId) && getAllUser().find(g => g.id === userId).avatar !== null ? "https://cdn.discordapp.com/avatars/" + userId + "/" + getAllUser().find(g => g.id === userId).avatar + ".png" : "/images/Default_Icon_Blue.png")}></img>
                                        )
                                    :
                                        (w === "{featureName}" ?
                                            config.features.find(f => f.name === featureId).icon
                                        :
                                            config.pages.find(p => p.name === w).icon) 
                                    )
                                )
                                }
                                            
                            arrow={( page.navpath.endsWith(config.pages.find(p => p.name === w).name) ? false : true )} key={"navigationitem." + w} />)) }
                        </ol>
                    </nav>
                    <p className="main_body_navigation_conatiner_navigation_title">{(!(l("general." + page.name).includes("{") && l("general." + page.name).includes("}")) ? l("general." + page.name) : page.name.replace("{guildName}", (getAllGuilds().find(g => g.id === guildId) ? getAllGuilds().find(g => g.id === guildId).name  : l("general.Loading"))).replace("{userName}", (getAllUser().find(g => g.id === userId) ? (getAllUser().find(g => g.id === userId).global_name ? getAllUser().find(g => g.id === userId).global_name : getAllUser().find(g => g.id === userId).username) : "Loading")).replace("{featureName}", l("features." + featureId + "-title")))}</p>
                </div>
                <div className="main_body_navigation_container_profile">
                    <div className="main_body_navigation_container_profile_sidebartoggle" onClick={showSmallSidebar}>
                        <div className="main_body_navigation_container_profile_sidebartoggle_container">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" focusable="false" className="main_body_navigation_container_profile_sidebartoggle_container_svg" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M80 160h352M80 256h352M80 352h352"></path>
                            </svg>
                        </div>
                    </div>
                    <button type="button" className="main_body_navigation_container_profile_darkmodetoggle" aria-label="Toggle color mode" onClick={() => { toggleTheme(); addAlert({ title: "", style: "danger", size: "big", disabled: false, content1: "Hey, It seems you found our ThemeSystem... Lets just say it's Work in Progress 😅" })}}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" focusable="false" className="main_body_navigation_container_profile_darkmodetoggle_svg" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M277.3 32h-42.7v64h42.7V32zm129.1 43.7L368 114.1l29.9 29.9 38.4-38.4-29.9-29.9zm-300.8 0l-29.9 29.9 38.4 38.4 29.9-29.9-38.4-38.4zM256 128c-70.4 0-128 57.6-128 128s57.6 128 128 128 128-57.6 128-128-57.6-128-128-128zm224 106.7h-64v42.7h64v-42.7zm-384 0H32v42.7h64v-42.7zM397.9 368L368 397.9l38.4 38.4 29.9-29.9-38.4-38.4zm-283.8 0l-38.4 38.4 29.9 29.9 38.4-38.4-29.9-29.9zm163.2 48h-42.7v64h42.7v-64z"></path>
                        </svg>
                    </button>
                    <button id="menu-button-:r1:" aria-expanded="false" aria-haspopup="menu" aria-controls="menu-list-:r1:" className="main_body_navigation_container_profile_dropdowntoggle" onClick={() => navigate("/user/profile")}>
                        <span className="main_body_navigation_container_profile_dropdowntoggle_container">
                            <span className="main_body_navigation_container_profile_dropdowntoggle_container_imgcontainer" data-loaded="">
                                <img src={("https://cdn.discordapp.com/avatars/" + user.id + "/" + user.avatar + "?size=512").includes("null") || ("https://cdn.discordapp.com/avatars/" + user.id + "/" + user.avatar + "?size=512").includes("undefined") ? "/images/Default_Icon_Blue.png" : "https://cdn.discordapp.com/avatars/" + user.id + "/" + user.avatar + "?size=512"} alt="" className="main_body_navigation_container_profile_dropdowntoggle_container_imgcontainer_img" />
                            </span>
                        </span>
                    </button>
                    <div style={{visibility: "hidden", position: "absolute", minWidth: "max-content", inset: "0px auto auto 0px"}} className="main_body_navigation_container_profile_dropdown">
                        <div className="main_body_navigation_container_profile_dropdown_container" tabIndex="-1" role="menu" id="menu-list-:r1:" aria-orientation="vertical" style={{opacity: 0, visibility: "hidden", transform: "scale(0.8) translateZ(0px)"}}>
                            <div className="main_body_navigation_container_profile_dropdown_container_header">
                                <p className="main_body_navigation_container_profile_dropdown_container_header_title">
                                    <span aria-label="Hi" role="img">👋</span>
                                    &nbsp; Hey, {user.username}
                                </p>
                            </div>
                            <div className="main_body_navigation_container_profile_dropdown_container_items">
                                <button id="menu-list-:r1:-menuitem-:r2:" role="menuitem" tabIndex="-1" className="a main_body_navigation_container_profile_dropdown_container_items_normal" onClick={() => { navigate("/user/profile") }} style={{ cursor: "pointer" }} data-index="0">
                                    <p className="main_body_navigation_container_profile_dropdown_container_items_text">Profile</p>
                                </button>
                                <button type="button" id="menu-list-:r1:-menuitem-:r3:" role="menuitem" tabIndex="-1" className="main_body_navigation_container_profile_dropdown_container_items_button" data-index="1">
                                    <p className="main_body_navigation_container_profile_dropdown_container_items_text">Logout</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}