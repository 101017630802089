import { Colorful } from '@uiw/react-color';
import { useLanguage } from '../../contextProvider/LanguageContext';
import './ColorForm.modules.css';

export default function ColorForm({ value, setValue }) {
    const { l } = useLanguage();
    return (
        <div className="css-1u379ko">
            <div className="css-11nrrcx">
                {(value !== "" ? <div className="css-1t1wprt" style={{ backgroundColor: value }}><p className="chakra-text css-13e9gjs"></p></div> : <div className="css-1t1wprt"><p className="chakra-text css-13e9gjs">No Color</p></div>)}
                <input placeholder="Select a color" autocomplete="off" name="color" id="field-:rl:" className="chakra-input css-119rmy1" value={value} />
            </div>
            <Colorful style={{ width: "100%" }} color={value} disableAlpha={true} onChange={(color) => setValue(color.hex)} />
        </div>
    )
}

/*<div style={{ "width": "100%" }} className="react-colorful">
    <div className="react-colorful__saturation" style={{ "backgroundColor": "rgb(255, 0, 0)" }}>
        <div aria-label="Color" aria-valuetext="Saturation 0%, Brightness 0%" className="react-colorful__interactive" tabIndex="0" role="slider">
            <div className="react-colorful__pointer react-colorful__saturation-pointer" style={{ top: "100%", left: "0%" }}>
                <div className="react-colorful__pointer-fill" style={{ backgroundColor: "rgb(0, 0, 0)" }}>
                </div>
            </div>
        </div>
    </div>
    <div className="react-colorful__hue react-colorful__last-control">
        <div aria-label="Hue" aria-valuenow="0" aria-valuemax="360" aria-valuemin="0" className="react-colorful__interactive" tabIndex="0" role="slider">
            <div className="react-colorful__pointer react-colorful__hue-pointer" style={{ top: "50%", left: "0%" }}>
                <div className="react-colorful__pointer-fill" style={{ backgroundColor: "rgb(255, 0, 0)" }}>
                </div>
            </div>
        </div>
    </div>
</div>*/