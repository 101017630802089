import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en_EN');

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const languageData = {
    en_EN: require("../language/en_EN"),
  };

  function l(text="", options = []) {
    const ar = text.split(".")
    if(!languageData[language][ar[0]]) return text;
    if(ar.length === 2 && !languageData[language][ar[0]][ar[1]]) return text;
    if(ar.length === 3 && !languageData[language][ar[0]][ar[1] + "." + ar[2]]) return text;
    let str = "";
    if(ar.length === 2) str = languageData[language][ar[0]][(ar[1] || "title")];
    if(ar.length === 3) str = languageData[language][ar[0]][(ar[1] + "." + ar[2])];
    if(str === "") str = text;
    options.forEach(opt => {
      if (opt.length === 2) {
        if (str.includes("{" + opt[0] + "}")) {
          str = str.replace("{" + opt[0] + "}", opt[1])
        }
      }
    })
    return str;
  }

  return (
    <LanguageContext.Provider value={{ l, language, changeLanguage, languageData }}>
      {children}
    </LanguageContext.Provider>
  );
};