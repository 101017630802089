import { useNavigate } from "react-router-dom";
import "./SmallSidebarNavigationItem.modules.css";

export default function SidebarNavigationItem({ name, link, svgicon, enabled }) {
    const navigate = useNavigate();
    return (
        <button className={ enabled ? "a smallsidebarnavigationitem_main_enabled" : "a smallsidebarnavigationitem_main" } onClick={() => { navigate(link) }} style={{ cursor: "pointer" }}>
            <div className={ enabled ? "smallsidebarnavigationitem_main_icon_enabled" : "smallsidebarnavigationitem_main_icon" }>
                { svgicon }
            </div>
            <p className="smallsidebarnavigationitem_main_text">{name}</p>
        </button>
    )
}