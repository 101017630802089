import { useLanguage } from "../contextProvider/LanguageContext";
import './FeatureBody.modules.css';
import { useRef, useState } from "react";
import Form from "./forms/Form";
import { useGuildDataContext } from "../contextProvider/GuildContext";

export default function FeatureBody({ config, guildData, id, featureId }) {
    const { updateGuildData } = useGuildDataContext(id);
    const { l } = useLanguage();
    const [actualFeatureId, setActualFeatureId] = useState(featureId);
    const oldGuildData = useRef(guildData);
    function createState(cid, vid) {
        let feature = config.features.find(f => f.name === featureId);
        if(!feature.categories[cid] || !feature.categories[cid]?.variables[vid]) return undefined;
        let variable = feature.categories[cid]?.variables[vid];
        let initialData = null;

        if (variable?.name.split(".").length === 2) {
            if (variable.customConfig) {
                initialData = guildData?.config?.[variable.customConfig]?.[variable?.name.split(".")[1]];
            } else {
                initialData = guildData?.config?.[featureId]?.[variable?.name.split(".")[0]][variable?.name.split(".")[1]];
            }
        } else {
            initialData = guildData?.config?.[featureId]?.[variable?.name];
        }

        return initialData || variable?.value;
    }
    const variables = {}
    for (let categoryId = 0; categoryId < 10; categoryId++) {
        for (let variableId = 0; variableId < 10; variableId++) {
            const stateVarName = `c${categoryId}v${variableId}`;
            variables[stateVarName] = createState(categoryId, variableId);
        }
    }
    const [states, setStates] = useState(variables);

    const setVariableState = (categoryId, variableId, value) => {
        console.log("setVariableState " + categoryId + " " + variableId + " " + value)
        const key = `c${categoryId}v${variableId}`;
        setStates(prevStates => ({
          ...prevStates,
          [key]: value
        }));
      };

    function saveChanges(e) {
        e.preventDefault();
        const feature = config.features.find(f => f.name === featureId);

        const setVariable = (cindex, vindex) => {
            const variableName = feature?.categories[cindex].variables[vindex]?.name || "";

            if (variableName.split(".").length === 2) guildData.config[featureId][variableName.split(".")[0]][variableName.split(".")[1]] = states[`c${cindex}v${vindex}`]
            else guildData.config[featureId][variableName] = states["c" + cindex + "v" +vindex]
        };

        for (let categoryId = 0; categoryId < 10; categoryId++) {
            for (let variableId = 0; variableId < 10; variableId++) {
                if (feature?.categories[categoryId]?.variables[variableId]) setVariable(categoryId, variableId);
            }
        }

        updateGuildData(id, oldGuildData.current, guildData)
        oldGuildData.current = guildData
    }

    function discardChanges(e) {
        e.preventDefault();
        const feature = config.features.find(f => f.name === featureId);

        const setVariable = (cindex, vindex) => {
            const variableName = feature?.categories[cindex].variables[vindex]?.name || "";

            const value = guildData?.config?.[featureId]?.[variableName.split(".")[0]]?.[variableName.split(".")[1]] || guildData?.config?.[featureId]?.[variableName] || feature?.categories[cindex]?.variables[vindex]?.variable?.value;

            setVariableState(cindex, vindex, value)
        };

        for (let categoryId = 0; categoryId < 10; categoryId++) {
            for (let variableId = 0; variableId < 10; variableId++) {
                if (feature?.categories[categoryId]?.variables[variableId]) setVariable(categoryId, variableId);
            }
        }
    }

    if (actualFeatureId !== featureId) {
        setActualFeatureId(featureId)
        for (let categoryId = 0; categoryId < 10; categoryId++) {
            for (let variableId = 0; variableId < 10; variableId++) {
                setVariableState(categoryId, variableId, createState(categoryId, variableId))
            }
        }
    }

    /*
    if( 
        ( var0 !== createState(0) && var0 === createOldState(0) ) ||
        ( var1 !== createState(1) && var1 === createOldState(1) ) ||
        ( var2 !== createState(2) && var2 === createOldState(2) ) ||
        ( var3 !== createState(3) && var3 === createOldState(3) ) ||
        ( var4 !== createState(4) && var4 === createOldState(4) ) ||
        ( var5 !== createState(5) && var5 === createOldState(5) ) ||
        ( var6 !== createState(6) && var6 === createOldState(6) ) ||
        ( var7 !== createState(7) && var7 === createOldState(7) ) ||
        ( var8 !== createState(8) && var8 === createOldState(8) ) ||
        ( var9 !== createState(9) && var9 === createOldState(9) )
    ) {
        oldGuildData.current = guildData;
        setVar0(() => createState(0));
        setVar1(() => createState(1));
        setVar2(() => createState(2));
        setVar3(() => createState(3));
        setVar4(() => createState(4));
        setVar5(() => createState(5));
        setVar6(() => createState(6));
        setVar7(() => createState(7));
        setVar8(() => createState(8));
        setVar9(() => createState(9));
    }
        */

    /*config.features.find(f => f.name === featureId).variables.map(v => {
        console.log(v.type)
        console.log(`var${config.features.find(f => f.name === featureId).variables.indexOf(v)}`)
        console.log(`setVar${config.features.find(f => f.name === featureId).variables.indexOf(v)}`)
        console.log("features." + featureId + "-" + v.name + "-title")
        console.log("features." + featureId + "-" + v.name + "-description")
        console.log(!(guildData?.config?.[featureId]?.enabled || featureId.startsWith("settings")))
        console.log("features." + featureId + "-" + v.name + "-placeholder")
        console.log("features." + featureId + "-" + v.name + "-notFoundPlaceholder")
        console.log(guildData)
        console.log(v.options)
        console.log("form." + v.name)
    })*/

    function checkChanges() {
        let change = false;
        for (let categoryId = 0; categoryId < 10; categoryId++) {
            for (let variableId = 0; variableId < 10; variableId++) {
                if (states[`c${categoryId}v${variableId}`] !== createState(categoryId, variableId)) change = true;
            }
        }
        return change;
    }


    return (
        <div className="featurebody">
            <div className="featurebody_container">
                <form className="featurebody_container_form">
                    {(featureId.startsWith("settings") || featureId === "vanity" ? <></> : <div className="featurebody_container_form_card">
                        <div>
                            <h2 className="featurebody_container_form_card_title">{l("features." + featureId + "-title")}</h2>
                            <p className="featurebody_container_form_card_description">{l("features." + featureId + "-description")}</p>
                        </div>
                        <div role="group" className="featurebody_container_form_card_btn" data-orientation="horizontal">
                            <button type="button" className="featurebody_container_form_card_btn_button" id="feature-enable-button" style={(guildData?.config?.[featureId]?.enabled ? { background: "var(--feature-enable-button-red)" } : { background: "var(--feature-enable-button-green)" })} onClick={() => { if (guildData && guildData.config && guildData.config?.[featureId]) { guildData.config[featureId].enabled = !guildData?.config?.[featureId]?.enabled; updateGuildData(id, oldGuildData.current, guildData); oldGuildData.current.config[featureId].enabled = guildData?.config?.[featureId]?.enabled; } }}>{guildData?.config?.[featureId]?.enabled ? l("general.Disable") : l("general.Enable")}</button>
                        </div>
                    </div>)}
                    
                        {config.features.find(f => f.name === featureId).categories.map(c => <div className="featurebody_container_form_card_container" style={guildData?.config?.[featureId]?.enabled || featureId.startsWith("settings") ? {} : { opacity: "20%" }}>{c.variables.map(v => <Form type={v.type} value={states[`c${config.features.find(f => f.name === featureId).categories.indexOf(c)}v${config.features.find(f => f.name === featureId).categories[config.features.find(f => f.name === featureId).categories.indexOf(c)].variables.indexOf(v)}`]} setValue={e => {console.log(e); setVariableState(config.features.find(f => f.name === featureId).categories.indexOf(c),config.features.find(f => f.name === featureId).categories[config.features.find(f => f.name === featureId).categories.indexOf(c)].variables.indexOf(v),e)}} title={l("features." + featureId + "-" + v.name + "-title")} description={l("features." + featureId + "-" + v.name + "-description")} disabled={!(guildData?.config?.[featureId]?.enabled || featureId.startsWith("settings"))} placeholder={l("features." + featureId + "-" + v.name + "-placeholder")} notFoundPlaceholder={l("features." + featureId + "-" + v.name + "-notFoundPlaceholder")} guildData={guildData} options={v.options} key={"form." + v.name} />)}</div>)}
                    

                    {oldGuildData.current?.config?.[featureId]?.enabled && checkChanges() ? (
                        <div className="featurebody_save" style={{ "transform": "translateX(0px) translateY(8px) translateZ(0px)" }}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" focusable="false" className="featurebody_save_icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path fill="none" d="M0 0h24v24H0z">
                                    </path>
                                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z">
                                    </path>
                                </g>
                            </svg>
                            <p className="featurebody_save_title">{l("general.Save_Changes")}</p>
                            <div className="featurebody_save_holder">
                            </div>
                            <div role="group" className="featurebody_save_buttons" data-orientation="horizontal">
                                <button type="submit" className="featurebody_save_buttons_save" onClick={e => saveChanges(e)}>
                                    <span className="featurebody_save_buttons_save_icon">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M465.94 119.76l-73.7-73.7A47.68 47.68 0 00358.3 32H96a64 64 0 00-64 64v320a64 64 0 0064 64h320a64 64 0 0064-64V153.7a47.68 47.68 0 00-14.06-33.94zM120 112h176a8 8 0 018 8v48a8 8 0 01-8 8H120a8 8 0 01-8-8v-48a8 8 0 018-8zm139.75 319.91a80 80 0 1176.16-76.16 80.06 80.06 0 01-76.16 76.16z">
                                            </path>
                                            <circle cx="256" cy="352" r="48">
                                            </circle>
                                        </svg>
                                    </span>{l("general.Save")}</button>
                                <button type="button" className="featurebody_save_buttons_discard" onClick={e => discardChanges(e)}>{l("general.Discard")}</button>
                            </div>
                        </div>
                    ) : <></>}

                </form>
            </div>
        </div>
    )
}