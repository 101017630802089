import "./FeatureSidebarCard.modules.css";
import { useNavigate } from "react-router-dom";

export default function CustomFeatureSidebarCard({ config, id, active, name, link, icon }) {
    const navigate = useNavigate();
    return (
        <button className={"a featuresidebar_body_features_feature" + (active ? "_active" : "")} onClick={() => { navigate(link) }} style={{ cursor: "pointer" }}>
            <div className={"featuresidebar_body_features_feature_icon" + (active ? "_active" : "")}>
                {icon}
            </div>
            <p className="featuresidebar_body_features_feature_text">{name}</p>
        </button>
    )
}