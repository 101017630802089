import { Navigate, useLocation, useParams } from "react-router-dom";
import { useLanguage } from "../contextProvider/LanguageContext";
import { useGuildDataContext } from "../contextProvider/GuildContext";

export default function MetaSystem({ config, children }) {
    const { l } = useLanguage();
    const { pathname } = useLocation();
    const { guildId, featureId } = useParams();
    const { getAllGuilds } = useGuildDataContext();
    const page = config.pages.find(p => p.path.replace("{guildId}", `${guildId}`).replace("{featureId}", `${featureId}`) === pathname || p.path.replace("{guildId}", `${guildId}`).replace("{featureId}", `${featureId}`) + "/" === pathname) || { name: "Main", path: "/user/home", navpath: "Main", icon: (<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M261.56 101.28a8 8 0 00-11.06 0L66.4 277.15a8 8 0 00-2.47 5.79L63.9 448a32 32 0 0032 32H192a16 16 0 0016-16V328a8 8 0 018-8h80a8 8 0 018 8v136a16 16 0 0016 16h96.06a32 32 0 0032-32V282.94a8 8 0 00-2.47-5.79z"></path><path d="M490.91 244.15l-74.8-71.56V64a16 16 0 00-16-16h-48a16 16 0 00-16 16v32l-57.92-55.38C272.77 35.14 264.71 32 256 32c-8.68 0-16.72 3.14-22.14 8.63l-212.7 203.5c-6.22 6-7 15.87-1.34 22.37A16 16 0 0043 267.56L250.5 69.28a8 8 0 0111.06 0l207.52 198.28a16 16 0 0022.59-.44c6.14-6.36 5.63-16.86-.76-22.97z"></path></svg>), title: "TPG Dashboard", description: "Simplica's Dashboard - Powered by TPG-Development" }
     
    if (guildId && !Number(guildId)) return (<Navigate to="/user/home" replace />)
    if (featureId && l("features." + featureId + "-title").startsWith("TranslationError")) return (<Navigate to="/user/home" replace />)
    if (!featureId) {
        if (!guildId) {
            document.title = page.title.replace("{pageName}", page.name);
            document.getElementsByTagName('meta')["description"].content = l("pages" + page.name + "-description").replace("{pageName}", page.name);
            document.querySelector('meta[property="og:title"]').setAttribute("content", page.title.replace("{pageName}", page.name));
            document.querySelector('meta[property="og:description"]').setAttribute("content", l("pages." + page.name + "-description").replace("{pageName}", page.name));
        } else {
            document.title = page.title.replace("{pageName}", page.name).replace("{guildName}", (getAllGuilds().find(g => g.id === guildId) ? getAllGuilds().find(g => g.id === guildId).name : "Loading"));
            document.getElementsByTagName('meta')["description"].content = l("pages." + page.name + "-description").replace("{pageName}", page.name).replace("{guildName}", (getAllGuilds().find(g => g.id === guildId) ? getAllGuilds().find(g => g.id === guildId).name : "Loading"));
            document.querySelector('meta[property="og:title"]').setAttribute("content", page.title.replace("{pageName}", page.name).replace("{guildName}", (getAllGuilds().find(g => g.id === guildId) ? getAllGuilds().find(g => g.id === guildId).name : "Loading")));
            document.querySelector('meta[property="og:description"]').setAttribute("content", l("pages." + page.name + "-description").replace("{pageName}", page.name).replace("{guildName}", (getAllGuilds().find(g => g.id === guildId) ? getAllGuilds().find(g => g.id === guildId).name : "Loading")));
        }
    } else {
        document.title = page.title.replace("{pageName}", page.name).replace("{guildName}", (getAllGuilds().find(g => g.id === guildId) ? getAllGuilds().find(g => g.id === guildId).name : "Loading")).replace("{featureName}", l("features." + featureId + "-title"));
        document.getElementsByTagName('meta')["description"].content = l("pages." + page.name + "-description").replace("{pageName}", page.name).replace("{guildName}", (getAllGuilds().find(g => g.id === guildId) ? getAllGuilds().find(g => g.id === guildId).name : "Loading")).replace("{featureName}", l("features." + featureId + "-title"));
        document.querySelector('meta[property="og:title"]').setAttribute("content", page.title.replace("{pageName}", page.name).replace("{guildName}", (getAllGuilds().find(g => g.id === guildId) ? getAllGuilds().find(g => g.id === guildId).name : "Loading")).replace("{featureName}", l("features." + featureId + "-title")));
        document.querySelector('meta[property="og:description"]').setAttribute("content", l("pages." + page.name + "-description").replace("{pageName}", page.name).replace("{guildName}", (getAllGuilds().find(g => g.id === guildId) ? getAllGuilds().find(g => g.id === guildId).name : "Loading")).replace("{featureName}", l("features." + featureId + "-title")));
    }

    return (
        <>{children}</>
    )
}