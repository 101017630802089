import { useNavigate } from "react-router-dom";
import "./SidebarProfile.modules.css";
import { useLanguage } from "../contextProvider/LanguageContext";

export default function SidebarProfile({ config, user }) {
    const navigate = useNavigate();
    const { l } = useLanguage();
    return (<div className="sidebarprofile">
        <div className="sidebarprofile_body">
            <span className="sidebarprofile_body_icon" data-loaded="">
                <img src={!user?.id || !user?.avatar ? "/images/Default_Icon_Blue.png" : "https://cdn.discordapp.com/avatars/" + user.id + "/" + user.avatar + "?size=512"} alt="" className="sidebarprofile_body_icon_img" />
            </span>
            <p className="sidebarprofile_body_text">{(user.global_name && user.global_name !== "" ? user.global_name : (user.username && user.username !== "" ? user.username : l("general.Loading")))}</p>
            <div className="sidebarprofile_body_space"></div>
            <button className="a sidebarprofile_body_settingsicon" onClick={() => { navigate("/user/profile") }} style={{ cursor: "pointer" }}>
                {config.getIcon("Settings")}
            </button>
        </div>
    </div>
    )
}