import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contextProvider/LanguageContext';
import './FeatureCard.modules.css';

export default function FeatureCard({ config, id, thisFeature, guildfeature }) {
    const { l } = useLanguage();
    let navigate = useNavigate();

    const configSvg = (<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M64 144h226.75a48 48 0 0090.5 0H448a16 16 0 000-32h-66.75a48 48 0 00-90.5 0H64a16 16 0 000 32zm384 224h-66.75a48 48 0 00-90.5 0H64a16 16 0 000 32h226.75a48 48 0 0090.5 0H448a16 16 0 000-32zm0-128H221.25a48 48 0 00-90.5 0H64a16 16 0 000 32h66.75a48 48 0 0090.5 0H448a16 16 0 000-32z"></path></svg>);
    const enableSvg = (<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224 304a16 16 0 01-11.31-27.31l157.94-157.94A55.7 55.7 0 00344 112H104a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h240a56.06 56.06 0 0056-56V168a55.7 55.7 0 00-6.75-26.63L235.31 299.31A15.92 15.92 0 01224 304z"></path><path d="M448 48H336a16 16 0 000 32h73.37l-38.74 38.75a56.35 56.35 0 0122.62 22.62L432 102.63V176a16 16 0 0032 0V64a16 16 0 00-16-16z"></path></svg>);
    return (
        <div className="featureCard" key={"featurecard." + thisFeature}>
            <div className="featureCard_body">
                <div className={( guildfeature !== null && guildfeature.enabled ? "featureCard_body_activeImg" : "featureCard_body_inactiveImg" )}>
                    {thisFeature.icon /*<img alt="" className="featureCard_body_img" src={"https://cdn.discordapp.com/icons/" + id + "/" + getAllGuilds().find(g => g.id === id).icon + ".png"} />*/}
                </div>
                <div className="featureCard_body_text">
                    <p className="featureCard_body_text_title">
                        {l("features." + thisFeature.name + "-title")}
                    </p>
                    <p className="featureCard_body_text_description">
                        {l("features." + thisFeature.name + "-description")}
                    </p>
                </div>
            </div>
            <div role="group" className="featureCard_body_btn" data-orientation="horizontal">
                <button onClick={() => { navigate('/guilds/' + id + '/features/' + thisFeature.name) }} type="button" className={( guildfeature !== null && guildfeature.enabled ? "featureCard_body_btn_activeButton" : "featureCard_body_btn_inactiveButton" )}>
                    <span className="featureCard_body_btn_button_img">
                        {( guildfeature !== null && guildfeature.enabled ? configSvg : enableSvg )}
                    </span>{( guildfeature !== null && guildfeature.enabled ? "Config" : "Enable" )}</button>
            </div>
        </div>
    )
}