import { createContext, useContext, useState } from 'react';

const InviteContext = createContext({});

export default InviteContext;

export const InviteProvider = ({ children }) => {
  const [inviteData, setInviteData] = useState({
    "1230767162143146044": {
      guildId: "1230767162143146044",
      invite: "",
      customLinks: [],
    }
  });

  const updateInviteData = (newData) => {
    setInviteData((prevData) => ({
      ...prevData,
      [newData.guildId]: {
        ...prevData[newData.guildId],
        ...newData,
      },
    }));
  };

  return (
    <InviteContext.Provider value={{ inviteData, updateInviteData }}>
      {children}
    </InviteContext.Provider>
  );
};

export const useInviteContext = (guildId, customInvite) => {
  const { inviteData, updateInviteData } = useContext(InviteContext);

  
  let inviteArray = [];
    for (let key in inviteData) {
      inviteArray.push(inviteData[key]);
    }

  const getInviteData = () => {
    if(guildId !== "") return inviteArray.find(g => g.guildId === guildId) || inviteArray.find(g => g.customLinks.includes(customInvite)) || "";
      else return inviteArray.find(g => g.customLinks.includes(customInvite)) || "";
  };

  return { getInviteData, updateInviteData };
};