import './SwitchForm.modules.css';

export default function SwitchForm({ value, setValue, disabled, placeholder, notFoundPlaceholder, guildData }) {

    return (
        <label className="form_switch">
            <input className="form_switch_input" type="checkbox" name="danger" style={{color: "var(--form_switch_input-color)", border: "0px", clip: "rect(0px, 0px, 0px, 0px)", height: "1px", width: "1px", margin: "-1px", padding: "0px", overflow: "hidden", whiteSpace: "nowrap", position: "absolute" }} value="" checked={(value === false ? false : true)} onChange={() => setValue((value === false ? true : false))} disabled={disabled} />
            <span aria-hidden="true" className={"form_switch_holder" + (value === true ? "_active" : "")}>
                <span className={"form_switch_switch" + (value === true ? "_active" : "")}></span>
            </span>
        </label>
    )
}