import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getCookie } from '../../../tools/CookieFunctions';
import { useWebSocketContext } from '../../../contextProvider/WebsocketContext';

import Loading from '../../../components/Loading'

export default function AuthCallback() {
  const [searchParams] = useSearchParams();
  const { sendMessage } = useWebSocketContext();
  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    if (!code) {
      return navigate('/api/auth', { replace: true });
    }

    if (state !== getCookie("loginState")) {
      return console.log("You needed too much time to login or are maybe clickjacked");
    }

    sendMessage({ type: "DiscordAuth", data: { code, session: getCookie("session"), token: getCookie("token") } });
    navigate('/', { replace: true }); // eslint-disable-next-line
  }, []);

  return (<Loading />);
}
