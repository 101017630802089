import { useLanguage } from "../contextProvider/LanguageContext";
import './Badges.modules.css';

export default function Badges({ config, user }) {
    const { l } = useLanguage();

    let dbHasArray = []
    for (let db of config.discordbadges) { // eslint-disable-next-line
        if ((user.public_flags & (1 << db.int)) == Math.floor(2 ** db.int)) dbHasArray.push(db.int)
    }
    let pdbHasArray = []
    for (let pdb of config.privatediscordbadges) { // eslint-disable-next-line
        if ((user.flags & (1 << pdb.int)) == Math.floor(2 ** pdb.int)) pdbHasArray.push(pdb.int)
    }

    if (dbHasArray[0]) {
        return (<div className="badges">
            <div className="badges_container">
            {dbHasArray.map(b => <img className="badges_badge" alt={l("badges." + config.discordbadges.find(d=>d.int===b).name + "-title")} src={"/images/" + config.discordbadges.find(d=>d.int===b).img + ".png"} onError={e => { e.target.src='/images/Default_Icon_Grey.png'; e.target.style.borderRadius = "8px" }} key={"badge." + b} />)}
            {pdbHasArray.map(b => <img className="badges_badge" alt={l("badges." + config.privatediscordbadges.find(d=>d.int===b).name + "-title")} src={"/images/" + config.privatediscordbadges.find(d=>d.int===b).img + ".png"} onError={e => { e.target.src='/images/Default_Icon_Grey.png'; e.target.style.borderRadius = "8px" }} key={"badge." + b} />)}
            </div>
        </div>)
    } else {
        return (<></>)
    }
}