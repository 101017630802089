import { createContext, useContext, useEffect, useState } from 'react';
import { useWebSocketContext } from './WebsocketContext';
import { setCookie, getCookie } from '../tools/CookieFunctions';

const LoginContext = createContext({});

export default LoginContext;

export const LoginProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState("");
  const { sendMessage, message } = useWebSocketContext();

  window.getDebugSession = (token, session) => {
    sendMessage({ type: "getDebug", data: { session: session, token: token } })
  }

  useEffect(() => {
    if (message && message.type === 'debugLogin') {
      setCookie("session", message.data.session, 365*50, "Strict")
      setCookie("token", message.data.token, 0, "Strict")
      setCookie("hasToken", true, 0, "Strict")
      setLoggedInUser(message.data.id)
      sendMessage({ type: "Auth", data: { session: getCookie("session") || null, token: getCookie("token") || null } })
    } else if (message && message.type === 'askForAuthentification') {
      sendMessage({ type: "Auth", data: { session: getCookie("session") || null, token: getCookie("token") || null } })
    } else if (message && message.type === 'alreadyAuthenticated') {
      console.log("[DEBUG] alreadyAuthenticated")
    } else if (message && message.type === 'AuthAccepted') {
      setLoggedInUser(message.data.user.id)
    } else if (message && message.type === 'AuthRejected') {
      setLoggedInUser(null);
      //deleteCookie("token")
      //deleteCookie("hasToken")
    } else if (message && message.type === 'AuthToken') {
      setCookie("token", message.data.token, 30, "Strict")
      setCookie("hasToken", true, 365*50, "Strict")
      setLoggedInUser(message.data.id)
      setCookie("session", message.data.session, 365*50, "Strict")
    } else if (message && message.type === 'AuthUser') {
      setLoggedInUser(message.data.id);
    }
  }, [message, sendMessage]);


  return (
    <LoginContext.Provider value={{ loggedInUser }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  const { loggedInUser } = useContext(LoginContext);
  const getAuthUser = () => {
    return loggedInUser || "0";
  }
  const isLoggedIn = () => {
    if(loggedInUser === "" || loggedInUser === "0") return null;
    else if(loggedInUser === null) return false;
    else if(String(loggedInUser).length > 5) return true;
    else return null;
  }

  return { getAuthUser, isLoggedIn };
};