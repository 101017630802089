import { useNavigate } from "react-router-dom";
import "./SidebarNavigationItem.modules.css";

export default function SidebarNavigationItem({ name, link, svgicon, enabled }) {
    const navigate = useNavigate();
    return (
        <button key={"sidebarnavigationitem." + link} className={ enabled ? "a sidebarnavigationitem_main_enabled" : "a sidebarnavigationitem_main" } onClick={() => { navigate(link) }} style={{ cursor: "pointer" }}>
            <div className={ enabled ? "sidebarnavigationitem_main_icon_enabled" : "sidebarnavigationitem_main_icon" }>
                { svgicon }
            </div>
            <p className="sidebarnavigationitem_main_text">{name}</p>
        </button>
    )
}